export default ({...props}) => {
    return (
        <svg viewBox="0 0 128 128" width={24} height={24} {...props}>
            <g fill='#fff' stroke="none">
                <path d="M122,37 C122.552285,37 123.052285,37.2238576 123.414214,37.5857864 C123.776142,37.9477153 124,38.4477153 124,39 L124,39 L124,89 C124,89.5522847 123.776142,90.0522847 123.414214,90.4142136 C123.052285,90.7761424 122.552285,91 122,91 L122,91 L6,91 C5.44771525,91 4.94771525,90.7761424 4.58578644,90.4142136 C4.22385763,90.0522847 4,89.5522847 4,89 L4,89 L4,39 C4,38.4477153 4.22385763,37.9477153 4.58578644,37.5857864 C4.94771525,37.2238576 5.44771525,37 6,37 L6,37 Z" id="矩形" stroke="black" stroke-width="3"></path>
                <rect id="矩形" stroke="black" x="23.5" y="37.5" width="1" height="54"></rect>
                <rect id="矩形备份-21" stroke="black" x="105.5" y="37.5" width="1" height="54"></rect>
            </g>
        </svg>
    )
}
