import {css} from "@emotion/react";
import {useState} from 'react';

import Directory from "./Directory";
import Viewer from "./Viewer";
import {useSearchParams} from "react-router-dom";
import useDocTitle from "@/hooks/useDocTitle.mjs";

const cssTheme = css({
    backgroundColor: '#28262b',
    color: 'rgb(255,255,255,0.65)',
})

const cssMain = css({
    display: 'flex',
    height: '100vh',
    overflow: 'hidden',

})

const VmIDE = () => {
    const [newOpenItem, setNewOpenItem] = useState(null)
    const [searchParams, setSearchParams] = useSearchParams()
    const vmId = searchParams.get('vmId')
    const vmName = searchParams.get('vmName')

    useDocTitle(`IDE·${vmName}`);

    const handleDirectorySelect = (item) => {
        if (item) {
            setNewOpenItem({...item})
        }
    }

    return (
        <>
            {/*<IDEHeader cssTheme={cssTheme}/>*/}
            <main css={[cssTheme, cssMain]}>
                <Directory onSelect={handleDirectorySelect}/>
                <Viewer newOpenItem={newOpenItem}/>
            </main>
        </>
    )
}

export default VmIDE;
