import ZoomIn from './icons/ZoomIn'
import ZoomOut from './icons/ZoomOut'
import StepBack from './icons/StepBack'
import StepFoward from './icons/StepFoward'
import AreaSelect from './icons/AreaSelect'
import {useEffect, useState} from "react";
import {css} from "@emotion/react";
import {Button, Select} from "antd";
import {useDiagramEditorContext} from "@/components/DiagramEditor/DiagramEditor";

const cssDiagramToolbar = css({
    gap: 8,
    padding: 8,
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #e5e5e5',
    zIndex: 10,
    background: '#f0f0f0',
    '& .toolbar-item': {
        width: 18,
        height: 18,
        float: 'left',
        cursor: 'pointer',
    },
    '& .active': {
        background: '#33a3dc'
    },
    '& .disabled path': {
        fill: 'white!important'
    },
    '& .disabled': {
        cursor: 'not-allowed'
    }
})
export default ({onSave, lf, activeEdges}) => {
    const [config] = useDiagramEditorContext();
    const toolbarConfig = config.toolbar ?? {};
    const [selectionOpened, setSelectionOpened] = useState(false);
    const [redoAble, setRedoAble] = useState(false);
    const [undoAble, setUndoAble] = useState(false);
    const [loading, setLoading] = useState(false);
    const handleSelection = () => {
        const newOpend = !selectionOpened;
        setSelectionOpened(newOpend)
        if (newOpend) {
            lf.extension.selectionSelect.openSelectionSelect()
        } else {
            lf.extension.selectionSelect.closeSelectionSelect()
        }
    }

    const handleZoomIn = () => {
        lf.zoom(true)
    }
    const handleZoomOut = () => {
        lf.zoom(false)
    }

    const handleRedo = () => {
        if (redoAble) {
            lf.redo()
        }
    }

    const handleUndo = () => {
        if (undoAble) {
            lf.undo()
        }
    }

    const handleLineType = (value) => {
        lf.setDefaultEdgeType(value)
        if (activeEdges && activeEdges.length > 0) {
            activeEdges.forEach(edge => {
                lf.graphModel.changeEdgeType(edge.id, value)
            })
        }
    }

    const handleSnapshot = () => {
        if (lf) {
            lf.getSnapshot()
        }
    }

    const handleSave = async (isCloseWindow) => {
        const data = lf.getGraphData()
        if (onSave) {
            onSave({
                loading,
                setLoading,
                isCloseWindow,
                data: JSON.stringify(data),
            });
        }
    }

    useEffect(() => {
        if (lf) {
            lf.on('history:change', ({data: {undoAble, redoAble}}) => {
                setRedoAble(redoAble);
                setUndoAble(undoAble);
            })
        }
    }, [lf]);

    return (
        <div css={cssDiagramToolbar}>
            <div className={`toolbar-item ${selectionOpened ? 'active' : ''}`} onClick={handleSelection}>
                <AreaSelect width={18} height={18}/>
            </div>
            <div className={`toolbar-item`} onClick={handleZoomIn}>
                <ZoomIn width={18} height={18}/>
            </div>
            <div className={`toolbar-item`} onClick={handleZoomOut}>
                <ZoomOut width={18} height={18}/>
            </div>
            <div className={`toolbar-item ${undoAble ? '' : 'disabled'}`} onClick={handleUndo}>
                <StepBack width={18} height={18}/>
            </div>
            <div className={`toolbar-item ${redoAble ? '' : 'disabled'}`} onClick={handleRedo}>
                <StepFoward width={18} height={18}/>
            </div>
            <div>
                <Select
                    style={{width: 120}}
                    size='small'
                    defaultValue={'pro-polyline'}
                    options={[
                        {
                            value: 'pro-polyline',
                            label: '折线',
                        },
                        {
                            value: 'pro-line',
                            label: '直线',
                        },
                        {
                            value: 'pro-bezier',
                            label: '曲线',
                        }
                    ]}
                    onChange={handleLineType}
                />
            </div>
            <Button size="small" onClick={handleSnapshot}>下载快照</Button>
            <Button type="primary" size="small" onClick={() => handleSave(false)} disabled={loading}>保存</Button>
            <Button type="primary" size="small" onClick={() => handleSave(true)} disabled={loading} style={toolbarConfig && toolbarConfig.saveCloseButton && toolbarConfig.saveCloseButton.show === false ? {display: 'none'} : null}>保存并关闭</Button>
        </div>
    )
}

