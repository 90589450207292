import RectNode from '../basic/RectNode'
import {getShapeStyleFuction} from "@/components/DiagramEditor/node/getShapeStyleUtil";

// 带圆角的矩形
class RectRadiusModel extends RectNode.model {
  initNodeData(data) {
    super.initNodeData(data)
    this.width = 100
    this.height = 60
  }
  getNodeStyle() {
    const style = super.getNodeStyle()
    const properties = this.getProperties()
    return getShapeStyleFuction(style, {backgroundColor: 'rgb(245, 245, 245)', borderWidth: '1px', borderColor: 'rgb(102, 102, 102)', ...properties})
  }
  setAttributes () {
    super.setAttributes()
    if (!this.text.value) {
      this.text.value = '线下作业'
    }
    this.radius = 20
  }
}
export default {
  type: 'busin-offlinejob',
  view: RectNode.view,
  model: RectRadiusModel
}
