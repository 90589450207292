import React, { useEffect, useState, useRef } from 'react';
import { Form, Row, Col, Input, Radio, Checkbox, Select, Button, Space, Modal, Tree, TreeSelect, Empty, Tabs, Table } from 'antd';
import { SearchOutlined, FolderOpenOutlined, CaretRightOutlined } from '@ant-design/icons';
// import Table from '@/pages/MainPage/components/Table';
import useHTTP from "@/hooks/useHTTP.mjs";
import runAsync from '@/script/runAsync';
import Page from '@/script/Page';

// 业务流程选择框
const Pane = ({ visible, setVisible, onCall, prjId, sVer, isSingle = true }) => {
    const http = useHTTP();
    const [form] = Form.useForm();

    const [dataList, setDataList] = useState([]);
    const [selectedList, setSelectedList] = useState([]);

    // 加载显示
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (visible) {
            form.resetFields();
            setSelectedList([]);
            doSearch();
        }
    }, [visible]);

    const doSearch = async () => {
        try {
            setLoading(true);

            const formData = form.getFieldsValue();
            formData.prjId = prjId;
            formData.sVer = sVer;
            formData.bfStsCodes = 'RLS,REVISE';

            const data = await runAsync(
                async () => {
                    return await http.get(`/bmbfs`, formData);
                }, {
                error: { content: (err) => `查询失败: ${err.message}` },
                loading: { show: true },
                success: { show: false },
            });

            setDataList(data ?? []);
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    const doReset = () => {
        form.resetFields();
        doSearch();
    }

    const doConfirm = () => {
        if (selectedList.length == 0) {
            return;
        } else {
            setVisible(false);
            onCall(selectedList[0]);
        }
    }

    const columns = [
        {
            title: '流程编号',
            dataIndex: 'bfNo',
            key: 'bfNo',
        },
        {
            title: '流程名称',
            dataIndex: 'bfName',
            key: 'bfName',
        },
    ];

    const rowSelection = {
        type: isSingle ? 'radio' : 'checkbox',
        selectedRowKeys: selectedList.map(({ pkid }) => pkid),
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedList(selectedRows);
        },
    }

    return (
        <Modal
            visible={visible}
            width={1000}
            title='选择业务流程'
            okText="确认"
            cancelText="取消"
            onOk={doConfirm}
            onCancel={() => setVisible(false)}
            bodyStyle={{ background: '#f0f2f5', padding: '12px' }}
        >
            <div style={{ padding: '12px 12px 0px 12px', background: '#fff', height: '500px' }}>
                {/* 查询条件 */}
                <div style={{ padding: '0px 12px 0px 12px', overflow: 'hidden', background: '#fff' }}>
                    <Form form={form} onFinish={doSearch}>
                        <Row gutter={24}>
                            <Col span={8}>
                                <Form.Item name='bfNo' label='流程编号'>
                                    <Input placeholder="请输入流程编号" allowClear />
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item name="bfName" label="流程名称">
                                    <Input placeholder="请输入流程名称" allowClear />
                                </Form.Item>
                            </Col>

                            <Col span={8} style={{ textAlign: 'right' }}>
                                <Button onClick={doReset} style={{ margin: '0 8px' }}>重置</Button>
                                <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>查询</Button>
                            </Col>
                        </Row>
                    </Form>
                </div>

                {/* 数据表格 */}
                <div style={{ marginTop: '0px', overflow: 'auto', background: '#fff' }}>
                    <Table
                        size="small"
                        loading={loading}
                        columns={columns}
                        dataSource={dataList}
                        pagination={Page.init(dataList)}
                        rowKey={record => record.pkid}
                        rowSelection={{ ...rowSelection }}
                        scroll={{ y: 330 }}
                    />
                </div>
            </div>
        </Modal>
    );
}

export default Pane;