import {css} from "@emotion/react";
import {useEffect, useState} from 'react';
import {Button, Form, Input, Space, Typography} from 'antd';
import {LockOutlined, UserOutlined} from '@ant-design/icons';

import IDEHeader from "@/pages/FrameworkIDE/IDEHeader";
import Directory from "@/pages/FrameworkIDE/Directory";
import Viewer from "@/pages/FrameworkIDE/Viewer";
import {useSearchParams} from "react-router-dom";
import useHTTP from "@/hooks/useHTTP.mjs";
import runAsync from "@/script/runAsync.mjs";
import {nanoid} from "nanoid";
import useDocTitle from "@/hooks/useDocTitle.mjs";

const cssTheme = css({
    backgroundColor: '#28262b',
    color: 'rgb(255,255,255,0.65)',
})

const cssMain = css({
    display: 'flex',
    height: '100vh',
    overflow: 'hidden',

})

const FrameworkIDE = () => {
    const [newOpenItem, setNewOpenItem] = useState(null)
    const [searchParams, setSearchParams] = useSearchParams()
    const ssCode = searchParams.get('ssUserCode')
    const ssName = searchParams.get('ssName')
    const contextPath = searchParams.get('contextPath')
    const mainPackage = searchParams.get('mainPackage')
    const agTplGroupId = searchParams.get('agTplGroupId')

    useDocTitle(`IDE·${ssName}`);

    const handleDirectorySelect = (item) => {
        if (item) {
            setNewOpenItem({...item})
        }
    }

    return (
        <>
            {/*<IDEHeader cssTheme={cssTheme}/>*/}
            <main css={[cssTheme, cssMain]}>
                <Directory onSelect={handleDirectorySelect}/>
                <Viewer newOpenItem={newOpenItem}/>
            </main>
        </>
    )
}

export default FrameworkIDE;
