import React, { useEffect, useState, useRef } from 'react';
import { Form, Row, Col, Input, Radio, Checkbox, Button, Space, Modal, Tree, TreeSelect, Empty, Tabs } from 'antd';
import { SearchOutlined, CaretRightOutlined } from '@ant-design/icons';
import Table from '@/pages/MainPage/components/Table';
import useHTTP from "@/hooks/useHTTP.mjs";
import runAsync from '@/script/runAsync';
import Page from '@/script/Page';

/**
 * SelectFmDialog ：选择功能模块【默认单选，可支持多选】
 * 
 * 使用场景：
 * 1：新建用户界面，选择所属功能模块；
 * 2：新建系统服务，选择所属功能模块；
 * 3：新建服务接口，选择所属功能模块；
 * 
 * 参数说明：
 * visible:     【必填】 当前Dialog是否展示 
 * setVisible:  【必填】 当前Dialog展示的操作方法 
 * onCall:      【必填】 回调方法  
 * prjId:       【必填】 prjId 项目ID
 * sVer:        【必填】 sVer  项目分支号
 * isSingle:    【可选】 true(单选、默认)  false(多选)  
 * isLeaf:      【可选】 true(只能选择叶子节点、默认)  false(可选择任意节点)  
 * allowEmpty:   【可选】 true(允许空返回，默认)  false(不允许空，至少选择一个)
 * @param {*} param
 * @returns 单选，返回对象；多选返回数组；
 **/
const Pane = ({ visible, setVisible, onCall, prjId, sVer, isSingle = true, isLeaf = true, allowEmpty = true, ...props }) => {
    const http = useHTTP();
    const { TabPane } = Tabs;
    const { DirectoryTree } = Tree;
    const [form] = Form.useForm();

    // 数据模型，表格多选服务
    const listCount = useRef(0);
    const [dataTree, setDataTree] = useState([]);
    const [dataList, setDataList] = useState([]);

    // 被选择的数据
    const [type, setType] = useState('tree');
    const [selectedTree, setSelectedTree] = useState(null); // 初始化为空
    const [selectedList, setSelectedList] = useState([]);
    const [checkedKeys, setCheckedKeys] = useState([]); // 选中的节点Keys数组

    // 加载显示
    const [loading, setLoading] = useState(false);

    // 组件挂载执行一次
    useEffect(() => {
        if (visible && prjId && sVer) {
            setType('tree');
            doTree();
        } else {
            setDataTree([]);
            setDataList([]);
            setSelectedTree(null);
            setSelectedList([]);
            setCheckedKeys([]);
        }

        listCount.current = 0;
    }, [visible]);

    // 查询功能模块Tree
    const doTree = async () => {
        try {
            setLoading(true);
            const data = await runAsync(
                async () => {
                    return await http.get(`/archfms/${prjId}/${sVer}/treelist`);
                }, {
                error: { content: (err) => `查询失败: ${err.message}` },
                loading: { show: true },
                success: { show: false },
            });

            // 由JSON字符串转换为JSON对象
            const tree = JSON.parse(data);

            // 遍历组装树结构
            setDataTree(tree.map(mapMenuNode));
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    // 搜索功能模块List
    const doSearch = async () => {
        try {
            setLoading(true);
            const formData = form.getFieldsValue();
            formData.prjId = prjId;
            formData.sVer = sVer;
            formData.faStsCodes = ['RLS', 'REVISE'];

            // 查询功能模块List
            if (prjId && sVer) {
                const data = await runAsync(
                    async () => {
                        return await http.get(`/archfms`, formData);
                    }, {
                    error: { content: (err) => `查询失败: ${err.message}` },
                    loading: { show: false },
                    success: { show: false },
                });

                setDataList(data);
            } else {
                setDataList([]);
                Modal.info({ title: '提示信息', content: '请选择项目' });
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    const doReset = () => {
        form.resetFields();
        setSelectedList([]);
        doSearch();
    }

    // 确认回调
    const doConfirm = () => {
        if (type == "tree") {
            if (isSingle) {
                if (null == selectedTree || selectedTree == undefined) {
                    if (allowEmpty) {
                        onCall(null);
                    } else {
                        return Modal.info({ title: '提示信息', content: '请选择功能模块' });
                    }
                } else {
                    if (isLeaf && !selectedTree.isLeaf) {
                        return Modal.info({ title: '提示信息', content: '请选择叶子节点功能模块' });
                    } else {
                        onCall({ prjId: selectedTree.prjId, fmId: selectedTree.pkid, fmCode: selectedTree.code, fmName: selectedTree.name, fmNamePath: selectedTree.fmNamePath });
                    }
                }
            } else {
                let fms = [];
                if (null != selectedTree && selectedTree != undefined) {
                    for (let i = 0; i < selectedTree.length; i++) {
                        let fm = selectedTree[i].dataObj;

                        // 过滤非子节点
                        // if (fm.isLeaf) {
                        fms.push({ prjId: fm.prjId, fmId: fm.pkid, fmCode: fm.code, fmName: fm.name, fmNamePath: fm.fmNamePath });
                        // }
                    }
                }
                onCall(fms);
            }
        }

        if (type == "list") {
            if (isSingle) {
                if (selectedList.length == 0) {
                    if (allowEmpty) {
                        onCall(null);
                    } else {
                        return Modal.info({ title: '提示信息', content: '请选择功能模块' });
                    }
                } else {
                    let fm = selectedList[0];
                    if (!fm.leaf && isLeaf) {
                        return Modal.info({ title: '提示信息', content: '请选择叶子节点功能模块' });
                    } else {
                        onCall({ prjId: fm.prjId, fmId: fm.pkid, fmCode: fm.fmCode, fmName: fm.fmName, fmNamePath: fm.fmNamePath });
                    }
                }
            } else {
                let fms = [];
                for (let i = 0; i < selectedList.length; i++) {
                    let fm = selectedList[i];
                    if (fm.leaf) { // 过滤非子节点
                        fms.push({ prjId: fm.prjId, fmId: fm.pkid, fmCode: fm.fmCode, fmName: fm.fmName, fmNamePath: fm.fmNamePath });
                    }
                }
                onCall(fms);
            }
        }
        doClose();
    }

    // 关闭Dialog
    const doClose = () => {
        setVisible(false);
        form.resetFields();
    }

    // 递归遍历
    const mapMenuNode = (obj) => {
        if (obj.child) {
            return {
                key: obj.pkid,
                title: obj.name,
                dataObj: { ...obj },
                // disabled: (isLeaf && !obj.isLeaf),
                children: obj.child.map(mapMenuNode)
            }
        } else {
            return {
                key: obj.pkid,
                title: obj.name,
                icon: <CaretRightOutlined />,
                dataObj: { ...obj }
            }
        }
    }

    const rowSelection = {
        type: isSingle ? 'radio' : 'checkbox',
        selectedRowKeys: selectedList.map(({ pkid }) => pkid),
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedList(selectedRows);
        },
        getCheckboxProps: (record) => {
            if (!record.leaf && isLeaf) {
                return { disabled: true };
            }
        },
    }

    // 表格列头定义
    const columns = [
        {
            title: '模块代码',
            dataIndex: 'fmUserCode',
            key: 'fmUserCode',
        },
        // {
        //     title: '模块名称',
        //     dataIndex: 'fmName',
        //     key: 'fmName',
        // },
        {
            title: '模块名称',
            dataIndex: 'fmNamePath',
            key: 'fmNamePath',
            // render: (text, record, index) => {
            //     return (<div dangerouslySetInnerHTML={{ __html: text }} style={{ marginLeft: record.stepSize * 6 + 'px' }}></div>)
            // },
        },
    ];

    return (
        <Modal
            visible={visible}
            width={1000}
            title='选择功能模块'
            okText="确认"
            cancelText="取消"
            onOk={() => doConfirm()}
            onCancel={() => doClose()}
            bodyStyle={{ background: '#f0f2f5', padding: '12px' }}
        >
            <div style={{ padding: '12px 12px 0 12px', overflow: 'hidden', background: '#fff', height: '480px' }}>
                {/*  树结构 */}
                <Tabs activeKey={type} type="card" style={{ overflow: 'hidden' }}
                    onChange={(value) => {
                        setType(value);
                        setSelectedTree([]);
                        setSelectedList([]);
                        setCheckedKeys([]);

                        if ('list' == value && listCount.current == 0) {
                            doSearch();
                            listCount.current = 1;
                        }
                    }}
                >
                    <TabPane tab="功能模块树" key="tree">
                        {
                            dataTree && dataTree.length > 0 ?
                                (
                                    (isSingle == true) ?
                                        <DirectoryTree
                                            height={410}
                                            // defaultExpandAll
                                            treeData={dataTree}
                                            selectedKeys={checkedKeys}
                                            onSelect={(selectedKeys, e) => {
                                                setSelectedTree(e.node.dataObj);
                                                setCheckedKeys(selectedKeys);
                                            }}
                                        />
                                        :
                                        <Tree
                                            height={410}
                                            showIcon
                                            checkable
                                            // defaultExpandAll
                                            treeData={dataTree}
                                            checkedKeys={checkedKeys}
                                            onCheck={(selectedKeys, selectedNodes) => {
                                                setSelectedTree(selectedNodes.checkedNodes);
                                                setCheckedKeys(selectedKeys);
                                            }}
                                        />
                                )
                                :
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        }
                    </TabPane>

                    {/*  搜索列表 */}
                    <TabPane tab="功能模块搜索" key="list" style={{ background: '#f0f2f5' }}>
                        {/* 查询条件 */}
                        <div style={{ padding: '0px 12px 0 12px', overflow: 'hidden', background: '#fff' }}>
                            <Form form={form} onFinish={doSearch}>
                                <Row gutter={24}>
                                    <Col span={6}>
                                        <Form.Item name='fmUserCode' label='模块代码'>
                                            <Input placeholder="请输入模块代码" />
                                        </Form.Item>
                                    </Col>

                                    <Col span={6}>
                                        <Form.Item name="fmName" label="模块名称">
                                            <Input placeholder="请输入模块名称" />
                                        </Form.Item>
                                    </Col>

                                    <Col span={12} style={{ textAlign: 'right' }}>
                                        <Button onClick={doReset} style={{ margin: '0 8px' }}>重置</Button>
                                        <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>查询</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </div>

                        {/* 数据表格 */}
                        <div style={{ marginTop: '0px', overflow: 'auto', height: '360px', background: '#fff' }}>
                            {/* 统计区 */}
                            {
                                selectedList.length > 0 &&
                                <div className="smstable-body-statis">
                                    <Space>
                                        <span>已选择</span>
                                        <span>{selectedList.length}</span>
                                        <span>项</span>
                                    </Space>
                                    <Space>
                                        <a onClick={() => setSelectedList([])}>
                                            取消选择
                                        </a>
                                    </Space>
                                </div>
                            }

                            {/* 数据区 */}
                            <div style={{ marginBottom: '0' }}>
                                <Table
                                    size="middle"
                                    columns={columns}
                                    dataSource={dataList}
                                    pagination={Page.init(dataList)}
                                    rowKey={record => record.pkid}
                                    rowSelection={{
                                        type: "checkbox",
                                        ...rowSelection,
                                    }}
                                    loading={loading}
                                />
                            </div>
                        </div>
                    </TabPane>
                </Tabs>
            </div>
        </Modal >
    );
}

export default Pane;
