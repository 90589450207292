import { useRef, useEffect, useState, forwardRef } from 'react';
import {
  transformToSchema,
  transformToTreeNode,
} from '@designable/formily-transformer'
import { Spin } from 'antd'

export default forwardRef((props, iframeRef) => {
  const [loading, setLoading] = useState(true);
  const [complate, setComplate] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { changeData, handleCtrlClick } = props;
  useEffect(() => {
    const handleMessage = (event) => {
      // 检查消息类型，处理数据
      if (event.data.type === 'linkPage') {
        const uvId = event.data.data.uvId;
        changeData && changeData(uvId)
      }
      if (event.data.type === 'ctrlClick') {
        const ctrlObj = event.data.data;
        handleCtrlClick && handleCtrlClick(ctrlObj)
      }
    };
    setLoading(true)
    const iframe = iframeRef.current;
    // const iframeOrigin = '*'; //
    if (iframe) {
      iframe.onload = () => {
        // 在iframe加载完成后，向其postMessage
        setLoading(false)
        setComplate(true)
        iframe.contentWindow.postMessage(JSON.stringify(transformToSchema(props.tree)), '*');
      };
    }
    // 添加监听器
    window.addEventListener('message', handleMessage);

    // 清理函数，移除监听器
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);
  useEffect(() => {
    if (complate) {
      setLoading(true)
      const iframe = iframeRef.current;
      setLoading(false)
      iframe.contentWindow.postMessage(JSON.stringify(transformToSchema(props.tree)), '*');
    }
  }, [props.tree])

  return (
    <>
      <iframe
        ref={iframeRef}
        style={{ border: 0, width: "100%", height: "100%", display:'block' }}
        src="/FormilyPreviewMobile"
        onError={() => setHasError(true)}
      >
        当前页面不支持iframe内嵌
      </iframe>
      {hasError && (
         <div
         style={{
           paddingTop: "120px",
           display: "flex",
           justifyContent: "center",
           position: "absolute",
           top: 0,
           left: 0,
           right: 0,
         }}
       >
          <p>页面无法加载，可能是因为该链接禁止被iframe嵌入。请<a href={src} target="_blank">点击这里直接访问</a>。</p>
        </div>
      )}
      {loading && (
        <div
          style={{
            paddingTop: "120px",
            display: "flex",
            justifyContent: "center",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
          }}
        >
          <Spin size="large" />
        </div>
      )}
    </>
  );
})