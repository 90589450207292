import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import useHTTP from "@/hooks/useHTTP.mjs";
import runAsync from '@/script/runAsync';

const Pane = ({ value, onChange, onCall, prjId, lockPrj, ...props }) => {
    const http = useHTTP();
    const [data, setData] = useState([]);
    const [prjs, setPrjs] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        doSearch();
    }, []);

    const doSearch = async () => {
        try {
            setLoading(true);
            const data = await runAsync(
                async () => {
                    return await http.get(`/prjs`);
                }, {
                error: { content: (err) => `查询项目失败: ${err.message}` },
                loading: { show: false },
                success: { show: false },
            });

            const options = [];
            for (const prj of data) {
                options.push({ value: prj.pkid, label: '[' + prj.prjNo + ']' + prj.prjName });
            }

            setPrjs(data);
            setData(options);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }

    return (
        <Select
            {...props}
            showSearch
            loading={loading}
            options={data}
            value={value ?? prjId}
            disabled={lockPrj}
            optionFilterProp="children"
            filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input?.toLowerCase())}
            onChange={(e) => {
                if (onChange) {
                    onChange(e);
                }

                // 回调
                const _prjs = prjs.filter(v => v.pkid == e);
                if (onCall && _prjs.length == 1) {
                    onCall(_prjs[0]);
                }
            }}
        />
    );
}

export default Pane;