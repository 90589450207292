import {css} from "@emotion/react";
import {useRef, useState, useEffect} from 'react';
import {Button, Form, Input, Space, Typography, Tree} from 'antd';
import {FileTextTwoTone, FolderTwoTone} from '@ant-design/icons';
import {nanoid} from "nanoid";
import {useSearchParams} from "react-router-dom";
import useHTTP from "@/hooks/useHTTP.mjs";
import runAsync from "@/script/runAsync.mjs";

const {DirectoryTree} = Tree;
const cssDirectory = css({
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    overflow: 'hidden',
    // borderRight: '1px solid #f0f0f0',
    width: 400,
    borderRight: '1px solid rgb(255,255,255,0.65)',
    // borderRight: '1.5px solid #0099CC',
    borderImage: 'linear-gradient(to bottom, #8f41e9, #0099CC, #8f41e9) 1'
})

const cssDirectoryHeader = css({
    height: 36,
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #f0f0f0',
    borderImage: 'linear-gradient(to right, #8f41e9, #0099CC, #8f41e9) 1',
    paddingLeft: 12,
    fontWeight: 'bold',
})

const cssDirectoryContent = css({
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    overflow: 'hidden',
    padding: 4,
})

const cssTree = css({
    backgroundColor: '#28262b',
    color: 'rgb(255,255,255,0.65)',
    '& .ant-tree-treenode:hover': {
        // background: '#f0f0f0',
        color: 'rgba(0,153,204,0.8)'
    },
    '& .ant-tree-switcher': {
        background: 'transparent',

    },
    '& .ant-tree-node-content-wrapper' : {
        whiteSpace: 'nowrap',
    }
})
const Directory = ({openFile, onSelect, ...props}) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const ssCode = searchParams.get('ssUserCode')
    const ssName = searchParams.get('ssName')
    const contextPath = searchParams.get('contextPath')
    const mainPackage = searchParams.get('mainPackage')
    const agTplGroupId = searchParams.get('agTplGroupId')

    const http = useHTTP();
    //表格 Loading 控制变量
    const [loading, setLoading] = useState(false);
    //树控件需要的数据结构
    const divRef = useRef(null)
    const [treeHeight, setTreeHeight] = useState(0);
    const [treeData, setTreeData] = useState([]);
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [autoExpandParent, setAutoExpandParent] = useState(true);
    const onExpand = (expandedKeysValue) => {
        setExpandedKeys(expandedKeysValue);
        setAutoExpandParent(false);
    };

    const doQuery = async () => {
        setLoading(true)
        try {
            const frameworkItems = await runAsync(
                async () => {
                    return await http.get("/getSsAgFrameworkPreviewTreeNodes", {
                        ssCode,
                        ssName,
                        contextPath,
                        mainPackage,
                        agTplGroupId,
                    })
                },
                {
                    error: {content: (err) => `查询脚手架项目失败: ${err.message}`},
                    loading: {show: false},
                    success: {show: false},
                }
            );

            const itemTreeData = [];

            const rootNodeKey = nanoid();
            const treeData = [
                {
                    key: rootNodeKey,
                    pKey: null,
                    title: ssCode,
                    pkid: rootNodeKey,
                    name: ssCode,
                    isRoot: true,
                    type: 'dir',
                    children: itemTreeData,
                    icon: <FolderTwoTone twoToneColor='#cd201f'/>,
                },
            ]

            if (frameworkItems) {
                const map = new Map();
                frameworkItems.map(({pkid, parentId, name, type, ...props}) => {
                    const node = {
                        ...props,
                        key: pkid,
                        pKey: parentId,
                        title: name,
                        parentId,
                        pkid,
                        name,
                        type,
                        children: [],
                        icon: type == 'file' ? <FileTextTwoTone twoToneColor='#52c41a'/> : <FolderTwoTone twoToneColor='#fa8c16'/>,
                    };
                    map.set(pkid, node);
                    return node;
                }).forEach(({key, pKey, children, ...props}) => {
                    //处理显示文本
                    const treeNode = {key, pKey, children, ...props};

                    if (!pKey) {
                        itemTreeData.push(treeNode);
                    } else {
                        const pTreeNode = map.get(pKey);
                        if (pTreeNode) {
                            pTreeNode.children.push(treeNode);
                        }
                    }
                });

            }

            setTreeData(treeData)
            setExpandedKeys(treeData[0].children.map(({key}) => key))
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        (async () => {
            setTreeHeight(divRef.current.clientHeight)
            doQuery();
        })()
    }, []);

    return (
        <div css={cssDirectory} ref={divRef} {...props}>
            <div css={cssDirectoryHeader}>
                {ssName}
            </div>
            <div css={cssDirectoryContent}>
                {
                    treeData.length > 0 &&
                    <DirectoryTree
                        css={cssTree}
                        height={treeHeight}
                        // motion={null}
                        defaultExpandAll={true}
                        showLine={{showLeafIcon: false,}}
                        showIcon={true}
                        multiple={false}
                        onSelect={(selectedKeys, {selected, selectedNodes, node, event}) => {
                            console.log(selectedNodes[0])
                            if (selected && onSelect && selectedNodes[0].type === 'file') {
                                onSelect(selectedNodes[0])
                            }
                        }}
                        onExpand={onExpand}
                        expandedKeys={expandedKeys}
                        autoExpandParent={autoExpandParent}
                        treeData={treeData}
                    />
                }
            </div>
        </div>
    )
}

export default Directory;
