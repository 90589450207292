import LogicFlow from '@logicflow/core'
import {SelectionSelect, Snapshot, MiniMap} from '@logicflow/extension'
import '@logicflow/core/dist/style/index.css'
import '@logicflow/extension/lib/style/index.css'
import {createContext, useContext, useEffect, useRef, useState} from "react";
import {css} from "@emotion/react";
import {Typography} from "antd";
import {registerCustomNode} from "@/components/DiagramEditor/node/registerCustomNode.mjs";
import DiagramSider from './DiagramSider'
import DiagramToolbar from "./DiagramToolbar";
import DiagramProperty from "@/components/DiagramEditor/DiagramProperty";
import DiagramContainer from "@/components/DiagramEditor/DiagramContainer";

const cssFlowDiagram = css({
    display: 'flex',
    flexGrow: '1',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
})

const DiagramEditorContext = createContext()
export const useDiagramEditorContext = () => useContext(DiagramEditorContext)

/**
 * 在线流程图编辑器
 * 基于logicFlow
 * @param data 初始流程图节点数据
 * @param onSave 保存回调方法
 * @param config 样式行为配置
 * @returns {JSX.Element}
 */
export default ({data, onSave, config = {},}) => {
    // 流程图对象
    const [lf, setLf] = useState();
    const refContainer = useRef();

    // 选择的边
    const [activeEdges, setActiveEdges] = useState([])
    // 选择的节点
    const [activeNodes, setActiveNodes] = useState([])
    // 当前元素属性
    const [elementProperty, setElementProperty] = useState({})

    const siderProps = {
        lf,
    }

    const containerProps = {
        lf,
        setLf,
        activeEdges,
        setActiveEdges,
        activeNodes,
        setActiveNodes,
        elementProperty,
        setElementProperty,
        refContainer,
        data,
        onSave,
    }

    const propertyProps = {
        lf,
        setLf,
        activeEdges,
        setActiveEdges,
        activeNodes,
        setActiveNodes,
        elementProperty,
        setElementProperty,
        onlyEdge: activeNodes.length === 0,
        refContainer,
    }

    return (
        <DiagramEditorContext.Provider value={[config]}>
            <main css={[cssFlowDiagram]}>
                <DiagramSider {...siderProps}/>
                <DiagramContainer {...containerProps}/>
                {
                    (activeNodes.length > 0 || activeEdges.length > 0) &&
                    <DiagramProperty {...propertyProps}/>
                }
            </main>
        </DiagramEditorContext.Provider>
    )
}
