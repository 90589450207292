import {useEffect, useRef, useState} from 'react';
import {Button, Form, Input, Segmented, Space, Typography} from 'antd';
import {LockOutlined, UserOutlined} from '@ant-design/icons';
import {login, login2} from '@/biz/user.mjs'
import favicon from '/favicon_long.png'
import ImgBackground from '/login_background5.png'
import useDocTitle from '@/hooks/useDocTitle.mjs'
import {useNavigate} from 'react-router-dom'
import {css} from "@emotion/react";
import runAsync from '@/script/runAsync.mjs'
import useHTTP from "@/hooks/useHTTP.mjs";

const {Text} = Typography;

/**
 * 验证码登录表单
 * @param loading
 * @param setLoading
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const MobileForm = ({loading, setLoading, ...props}) => {
    const http = useHTTP();
    const timeoutRef = useRef(null);
    const [countDown, setCountDown] = useState(0);
    // 表单
    const [form] = Form.useForm();

    const countDownTimer = async () => {
        const newCountDown = countDown - 1;
        setCountDown(newCountDown);
    }

    const handleSendVerifyCodeClick = async () => {
        try {
            form.validateFields(['phone']).then(async ({phone}) => {
                await runAsync(
                    async () => {
                        return await http.post(`/sms/login/${phone}`, {});
                    }, {
                        error: {content: (err) => `发送失败: ${err.message}`},
                        loading: {show: false},
                        success: {show: false},
                    });
                setCountDown(60)
            })
        } catch (err) {
            console.log(err);
        }
    }

    const handleFinish = async (values) => {
        try {
            setLoading(true);

            await runAsync(
                async () => login2(values),

                {
                    action: '登录',
                    loading: {show: false},
                }
            )
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }

    //页面初始化
    useEffect(() => {
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current)
            }
        }
    }, []);

    useEffect(() => {
        if (countDown > 0) {
            timeoutRef.current = setTimeout(() => {
                countDownTimer()
            }, 1000)
        }
    }, [countDown]);

    return (
        <Form form={form} onFinish={handleFinish} layout='vertical' {...props}>
            <Form.Item
                name="phone"
                rules={[
                    {
                        required: true,
                        message: '请输入手机号码',
                    },
                ]}
            >
                <Input
                    placeholder="手机号码"
                    addonBefore={"+86"}
                    suffix={countDown > 0 ? <Text type='secondary'>{countDown}秒</Text> : <a onClick={handleSendVerifyCodeClick}>获取验证码</a>}
                    size="large"
                />
            </Form.Item>
            <Form.Item
                name="verifyCode"
                rules={[
                    {
                        required: true,
                        message: '请输入验证码',
                    },
                ]}
            >
                <Input
                    placeholder="验证码"
                    size="large"
                />
            </Form.Item>
            <Button
                id="mobileSubmit"
                htmlType="submit"
                type="primary"
                size="large"
                block
                style={{
                    fontWeight: 'bold',
                    borderColor: '#fe4066',
                    background: '#fe4066',
                    marginBottom: 12,
                }}
                loading={loading}
            >
                登录
            </Button>
        </Form>
    )
}

/**
 * 密码登录表单
 * @param loading
 * @param setLoading
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const PwdForm = ({loading, setLoading, ...props}) => {
    const handleFinish = async (values) => {
        try {
            setLoading(true);

            await runAsync(
                async () => login(values),

                {
                    action: '登录',
                    loading: {show: false},
                }
            )
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }

    return (
        <Form onFinish={handleFinish} layout='vertical' {...props}>
            <Form.Item
                name="userNo"
                // label='用户名'
                rules={[
                    {
                        required: true,
                        message: '请输入用户名',
                    },
                ]}
            >
                <Input
                    placeholder="用户名"
                    // prefix={<UserOutlined className='login-prefix'/>}
                    size="large"
                />
            </Form.Item>
            <Form.Item
                name="password"
                // label='密码'
                rules={[
                    {
                        required: true,
                        message: '请输入密码',
                    },
                ]}
            >
                <Input.Password
                    placeholder="密码"
                    // prefix={<LockOutlined className='login-prefix'/>}
                    size="large"
                />
            </Form.Item>
            <Button
                id="pwdSubmit"
                htmlType="submit"
                type="primary"
                size="large"
                block
                style={{
                    fontWeight: 'bold',
                    borderColor: '#fe4066',
                    background: '#fe4066',
                    marginBottom: 12,
                }}
                loading={loading}
            >
                登录
            </Button>
        </Form>
    )
}

/**
 * 登录页面 css
 * @type {SerializedStyles}
 */
const cssLogin = css({
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    overflow: 'hidden',
    backgroundImage: `url(${ImgBackground})`,
    backgroundSize: 'cover',
    '& .container': {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        backgroundColor: 'rgba(53, 48, 48, 0.7)',
        '& .header': {
            padding: '32px 64px',
            display: 'flex',
            alignItems: 'center',
            '& .image': {
                height: 64,
                // marginRight: 10,
            }
        },
        '& .main': {
            flexGrow: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: ' center',
            '& .left-text': {
                display: 'flex',
                flexDirection: 'column',
                color: '#fff',
                '& h2': {
                    fontSize: 32,
                    fontWeight: 'bold',
                    color: '#fff',
                    margin: 0,
                },
                '& p': {
                    fontSize: 16,
                    fontWeight: 'bold',
                    marginTop: 12
                }
            },
            '& .right-panel': {
                marginLeft: 64,
                background: '#fff',
                borderRadius: 6,
                boxShadow: '0 16px 32px 0 rgb(0 0 0 / 8%)',
                display: 'flex',
                flexDirection: 'column',
                padding: 32,
                width: 488,
                // '& h1': {
                //     fontSize: 30,
                //     fontWeight: 'bold',
                //     marginBottom: 12
                // },
                // '& form .ant-form-item-explain-error': {
                //     fontWeight: 'bold',
                // }
            }
        },
        '& .footer': {
            color: 'rgba(255, 255, 255, 0.7)',
            padding: '32px 64px',
            '& a': {
                color: 'rgba(255, 255, 255, 0.7)',
            },
            '& a:hover': {
                color: 'rgba(255, 255, 255)',
            }
        },
    },
})

/**
 * 登录页面
 * @returns {JSX.Element}
 * @constructor
 */
const LoginPage = () => {
    useDocTitle('登录')
    const navigate = useNavigate();
    const http = useHTTP();

    // 登录验证模式
    const [loginModes, setLoginModes] = useState([ {
        label: '密码登录',
        value: 'loginByPwd',
    }])
    const [loginMode, setLoginMode] = useState('loginByPwd');

    // 加载
    const [loading, setLoading] = useState(false);

    const handleLoginModeChange = async (value) => {
        setLoginMode(value)
    }

    useEffect(() => {
        (async () => {
            setLoading(true)
            try {
                const data = await runAsync(
                    async () => {
                        return await http.get(`/tmusers/register/init`);
                    }, {
                        error: {content: (err) => `读取基本配置失败: ${err.message}`},
                        loading: {show: false},
                        success: {show: false},
                    });

                if (data.enableSms) {
                    setLoginModes([
                        ...loginModes,
                        {
                            label: '验证码登录',
                            value: 'loginByMobile',
                        },
                    ])
                }
            } finally {
                setLoading(false)
            }
        })()
    }, []);

    return (
        <div css={cssLogin}>
            <div className="container">
                <div className='header'>
                    <img
                        className='image'
                        alt="logo"
                        src={favicon}
                    />
                </div>
                <div className="main">
                    <div className="left-text">
                        <h2>高效迭代软件逻辑模型，持续提升研发交付效能</h2>
                        {/*<p>在线一站式高精度可视化软件设计建模平台，全面掌控软件逻辑，大幅提升软件工程质量和效率</p>*/}
                    </div>
                    <div className="right-panel">
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 30, fontWeight: 'bold', marginBottom: 32,}}>欢迎来到SLMap</div>

                        {
                            loginModes.length > 1 && (
                                <Segmented
                                    style={{marginBottom: 24,}}
                                    block
                                    size='large'
                                    value={loginMode}
                                    onChange={handleLoginModeChange}
                                    options={loginModes}
                                />
                            )
                        }

                        <MobileForm loading={loading} setLoading={setLoading} style={loginMode === 'loginByMobile' ? null : {display: 'none'}}/>
                        <PwdForm loading={loading} setLoading={setLoading} style={loginMode === 'loginByPwd' ? null : {display: 'none'}}/>

                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <span>
                                <Text type='secondary'>没有账号?</Text> <a href="" style={{color: '#FE4066', fontWeight: 'bold'}} onClick={() => navigate('/register')}>立即注册</a>
                            </span>
                            <a onClick={() => navigate('/findPwd')}>忘记密码?</a>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <div>©2016-2023 Hopedove Software Co.,Ltd. All rights reserved.</div>
                    <Space>
                        <a href='https://beian.miit.gov.cn/#/Integrated/index' target='_blank'>苏ICP备15043145号</a>
                        {/*<a href='#' target='_blank'>隐私政策</a>*/}
                        {/*<a href='#' target='_blank'>使用协议</a>*/}
                    </Space>
                </div>
            </div>

        </div>
    );
}

export default LoginPage;
