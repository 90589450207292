import {Form, Input, Modal, Select} from "antd";
import React from "react";


export default function MessageModal({visible, onOk, title, message, ...props}) {
    console.log(visible, onOk, title, message, props)
    return (
        <Modal
            visible={visible}
            title={title}
            onOk={()=> onOk(false)}
            cancelButtonProps={{style: {display: 'none'}}}
            width={1200}
            bodyStyle={{display: 'flex', height: '70vh', overflow: 'hidden'}}
            {...props}
        >
            <div style={{display: 'flex', flexGrow: 1, whiteSpace: 'pre', overflow: 'auto'}}>
                {message}
            </div>
        </Modal>
    )
}
