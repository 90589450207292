import {useEffect} from 'react'

export default (title = import.meta.env.VITE_APP_NAME) => {
    useEffect(
        () => {
            if (title) {
                document.title = title
            }
        },

        [title]
    )
}
