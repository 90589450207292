import {useEffect} from 'react'
import {css} from '@emotion/react'
import {notification} from 'antd'
import {join} from 'hopedove-dom/url'
import buildUrl from '@/script/buildUrl.mjs'
import {sharedEventBus} from '@/script/sharedEventBus.mjs'
import {useToken} from '@/biz/token.mjs'

const cssTimestamp = css({
    color: '#888',
    fontSize: 12,
    marginTop: 16,
})

const NotificationContent = ({
    erContent,
    erOptExtraData,
    erOptTypeCode,
    erTplLink,
    erTplLinkTitle,
    timeStamp,
}) => {
    if ('NEW_WINDOW' === erOptTypeCode) {
        const data = erOptExtraData ? JSON.parse(erOptExtraData) : {}
        const url = buildUrl(erTplLink, data)

        return (
            <div>
                <div>{erContent}</div>
                <div css={cssTimestamp}>{timeStamp}</div>

                <div>
                    <a
                        href={url}
                        rel="noreferrer"
                        target="_blank"
                    >
                        {erTplLinkTitle}
                    </a>
                </div>
            </div>
        )
    }
    else {
        return (
            <div>
                <div>{erContent}</div>
                <div>{timeStamp}</div>
            </div>
        )
    }
}

export default () => {
    const token = useToken()

    useEffect(
        () => {
            if (! token) {
                return
            }

            const es = new EventSource(
                join(
                    import.meta.env.VITE_HTTP_BASE_URL,
                    `/sse/connect/${token.token}`
                )
            )

            es.onmessage = e => {
                sharedEventBus.publish('sse', JSON.parse(e.data))
            }

            const handleSse = e => {
                const {
                    erContent,
                    erOptExtraData,
                    erOptTypeCode,
                    erTplLink,
                    erTplLinkTitle,
                    erTplName,
                    timeStamp,
                } = e.data

                notification.open({
                    description: (
                        <NotificationContent
                            erContent={erContent}
                            erOptExtraData={erOptExtraData}
                            erOptTypeCode={erOptTypeCode}
                            erTplLink={erTplLink}
                            erTplLinkTitle={erTplLinkTitle}
                            timeStamp={timeStamp}
                        />
                    ),

                    message: erTplName,
                })
            }

            sharedEventBus.subscribe('sse', handleSse)

            return () => {
                es.close()
                sharedEventBus.unsubscribe('sse', handleSse)
            }
        },

        [token]
    )
}
