import React, { useEffect, useState, useRef } from 'react';

const Pane = ({ data, keyword }) => {
    // const _keyword = <span style={{ color: '#0c23f1', background: '#fdd834' }}>{keyword}</span>;
    // const _dataList = data.split(keyword);
    // return <span>
    //     {
    //         _dataList.map((v, index) => {
    //             if (index < _dataList.length - 1) {
    //                 return <span><span>{v}</span>{_keyword}</span>;
    //             } else {
    //                 return <span>{v}</span>
    //             }
    //         })
    //     }
    // </span>;

    const _keyword = <span style={{ color: '#0c23f1', background: '#fdd834' }}>{keyword}</span>;
    const _lastSperator = data.lastIndexOf('/'); // 最后一个分隔符
    const _upperPart = data.substring(0, _lastSperator); // 上部分
    const _lowerPart = data.substring(_lastSperator, data.length); // 下部分
    const _dataList = _lowerPart.split(keyword);

    return <span>
        {_upperPart}
        {
            _dataList.map((v, index) => {
                if (index < _dataList.length - 1) {
                    return <span><span>{v}</span>{_keyword}</span>;
                } else {
                    return <span>{v}</span>
                }
            })
        }
    </span>;
}

export default Pane;