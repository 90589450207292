import useHTTP from '@/hooks/useHTTP.mjs'
import runAsync from '@/script/runAsync.mjs'
import {redirect} from 'react-router-dom'

export default () => {
    const http = useHTTP()

    const loader = async ({params: {suSc}}) => {
        try {
            const {suOlu} = await runAsync(
                () => http.get('/oneBasSu', {suSc}),

                {
                    error: {content: (err) => `重定向失败: ${err.message}`},
                    loading: {show: true},
                    success: {show: false},
                }
            )

            return redirect(suOlu)
            //history.replaceState(null, '', suOlu)
            ////location.href = suOlu
            //return null
        }
        catch (err) {
            console.error(err)
            return redirect('/')
        }
    }

    return {
        element: null,
        loader,
        path: '/s/:suSc',
    }
}
