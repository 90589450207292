import {h} from '@logicflow/core'
import RectNode from '../basic/RectNode'
import {getShapeStyleFuction, getTextStyleFunction} from '../getShapeStyleUtil'

// 除号
class SubFlowNodeModel extends RectNode.model {
    initNodeData(data) {
        super.initNodeData(data)
        this.width = 140
        this.height = 60
    }

    getNodeStyle() {
        const style = super.getNodeStyle()
        const properties = this.getProperties()
        return getShapeStyleFuction(style, properties)
    }

    getTextStyle() {
        const style = super.getTextStyle()
        const properties = this.getProperties()
        return getTextStyleFunction(style, properties)
    }

    setAttributes () {
        super.setAttributes()
        if (!this.text.value) {
            this.text.value = '子流程'
        }
    }
}

class SubFlowNodeView extends RectNode.view {
    getResizeShape() {
        const {x, y, width, height} = this.props.model //x，y 代表中心点
        const style = this.props.model.getNodeStyle()

        const startX = x - width / 2
        const startY = y - height / 2


        const attrs = {
            ...style,
            x,
            y,
            width,
            height,
        }

        // 最底层矩形
        const rectAttrs = {
            ...attrs,
            x: startX,
            y: startY,
        }

        // 左侧矩形
        const lineLeftAttrs = {
            ...attrs,
            x1: startX + 20,
            y1: startY,
            x2: startX + 20,
            y2: startY + height,
        }

        // 右侧矩形
        const lineRightAttrs = {
            ...attrs,
            x1: startX + width - 20,
            y1: startY,
            x2: startX + width - 20,
            y2: startY + height
        }

        return h('g', {}, [
            h('rect', {...rectAttrs}),
            h('line', {...lineLeftAttrs}),
            h('line', {...lineRightAttrs}),
        ])
    }

}

export default {
    type: 'busin-subflow',
    view: SubFlowNodeView,
    model: SubFlowNodeModel
}
