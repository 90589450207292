import { useState, useRef } from 'react';
import { Button, Form, Input, Space, Modal } from 'antd';
import { MobileOutlined, SendOutlined, VerifiedOutlined } from "@ant-design/icons";
import useDocTitle from '@/hooks/useDocTitle.mjs';
import { useNavigate } from 'react-router-dom';
import { css } from '@emotion/react';
import favicon from '/favicon_blue.png';
import useHTTP from "@/hooks/useHTTP.mjs";
import runAsync from "@/script/runAsync.mjs";
import {codeLogin} from '@/biz/user.mjs';

const FindPwd = () => {
    useDocTitle('忘记密码');
    const navigate = useNavigate();
    const http = useHTTP();
    const [form] = Form.useForm();
    const [form2] = Form.useForm();

    const ref = useRef({});
    const [ctime, setCtime] = useState();
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);

    // 下一步
    const doNext = async () => {
        try {
            setLoading(true);
            setCtime(null);
            const formData = await form.validateFields();
            const userNo = formData.userNo;
            const data = await runAsync(
                async () => {
                    return await http.get(`/tmusers/find/${userNo}`);
                }, {
                error: { content: (err) => `查询失败: ${err.message}` },
                loading: { show: false },
                success: { show: false },
            });

            if (data) {
                if (data.phone) {
                    ref.current.userNo = data.userNo;
                    ref.current.phone = data.phone;
                    form2.setFieldsValue({ phone: data.phone });
                    setVisible(true);
                } else {
                    Modal.info({ content: '账号未绑定手机号' });
                }
            } else {
                Modal.info({ content: '账号不存在' });
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    // 确认登录
    const doFind = async () => {
        try {
            setLoading(true);
            const data = await form2.validateFields();
            data.userNo = ref.current.userNo;
            data.phone = ref.current.phone;

            await runAsync(
                async () => codeLogin(data),

                {
                    action: '登录',
                    loading: { show: false },
                }
            )
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    const doCancel = () => {
        setVisible(false);
        setCtime(null);
    }

    // 发送短信
    const sendSms = async () => {
        try {
            const phone = ref.current.phone;
            if (phone) {
                countdown();
                await runAsync(
                    async () => {
                        return await http.post(`/sms/pwd/${phone}`, {});
                    }, {
                    error: { content: (err) => `发送失败: ${err.message}` },
                    loading: { show: false },
                    success: { show: false },
                });
            }
        } catch (err) {
            console.log(err);
        }
    }

    const countdown = () => {
        let count = 60;
        const siz = setInterval(() => {
            if (count <= -1) {
                clearInterval(siz);
                setCtime(null);
            } else {
                setCtime(count--);
            }
        }, 1000);
    }

    const cssLogo = css({
        height: 22,
        marginRight: 8,
    });

    return <div style={{ padding: '12px' }}>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Space>
                <a onClick={() => navigate('/login')}>登录</a>
                <a onClick={() => navigate('/register')}>注册</a>
            </Space>
        </div>

        <div style={{ marginLeft: '300px', width: '200px' }}>
            <img css={cssLogo} alt="logo" src={favicon} />|<span style={{ marginLeft: '8px' }}>账号设置</span>
        </div>

        <hr />
        <div style={{ marginLeft: '300px', width: '300px', fontWeight: 'bold' }}>找回密码</div>
        <hr />

        <div style={{ marginLeft: '300px', width: '300px' }}>
            <Form layout="vertical" form={form}>
                <Form.Item name="userNo" label="请填写您需要找回的账号" rules={[{ required: true, message: '请输入您的用户名' }]}>
                    <Input allowClear placeholder='请输入您的用户名' />
                </Form.Item>
                <Button type="primary" size="large" block style={{ fontWeight: 'bold', marginBottom: 12 }} onClick={doNext}>下一步</Button>
            </Form>
        </div>

        <Modal
            open={visible}
            title="安全验证"
            okText="确定"
            cancelText="取消"
            onCancel={doCancel}
            // onOk={doFind}
            destroyOnClose
            footer={[
                <Space>
                    <Button onClick={() => setVisible(false)}>取消</Button>
                    <Button type="primary" onClick={doFind} loading={loading}>确定</Button>
                </Space>
            ]}
        >
            <Form layout="vertical" form={form2}>
                <div>您的账号可能存在安全风险，为了确保为您本人操作，请先进行安全验证</div>
                <p />

                <Form.Item name="phone">
                    <Input readOnly prefix={<MobileOutlined />} />
                </Form.Item>

                <Space>
                    <Form.Item name="verifyCode" rules={[{ required: true, message: '请输入验证码' }]}>
                        <Input allowClear prefix={<VerifiedOutlined />} placeholder='请输入验证码' style={{ width: '365px' }} />
                    </Form.Item>

                    <Form.Item name="verifyCode2">
                        {
                            ctime ?
                                <Button style={{ width: '100px' }}>{ctime}</Button> :
                                <Button style={{ width: '100px' }} icon={<SendOutlined />} onClick={sendSms} />
                        }
                    </Form.Item>
                </Space>
            </Form>
        </Modal>
    </div>;
}

export default FindPwd;
