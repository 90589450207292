import {useEffect, useState} from 'react'
import {css} from '@emotion/react'
import useHTTP from '@/hooks/useHTTP.mjs'
import inlineSvg from '@/script/inlineSvg.mjs'
import SuspenseError from '@/script/SuspenseError.mjs'

const cssWatermark = css({
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundSize: '20% 10%',
    zIndex: 999999,
    pointerEvents: 'none',
    opacity: .2,
})

const Watermark = ({text, ...props}) => {
    if (! text) {
        return null
    }

    const watermark = inlineSvg(`
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 500 100"
        >
            <text
                font-size="32"
                text-anchor="middle"
                x="50%"
                y="50%"
            >${text}</text>
        </svg>
    `)

    const style = {
        backgroundImage: `url(${watermark})`,
    }

    return (
        <div
            css={cssWatermark}
            style={style}
            {...props}
        ></div>
    )
}

export default function License({children}) {
    const http = useHTTP()
    const [message, setMessage] = useState('')

    useEffect(
        () => {
            (async () => {
                try {
                    await http.get('/license/authentication')
                }
                catch (err) {
                    if (
                        [
                            '00000031',
                            '00000032',
                            '00000033',
                            '00000034',
                            '00000035',
                            '00000036',
                            '00000037',
                            '00000038',
                            '00000039',
                            '00000040',
                        ].includes(err.code)
                    ) {
                        setMessage(err.message)
                    }
                    else {
                        throw err
                    }
                }
            })()
        },

        []
    )

    return (
        <>
            {children}
            <Watermark text={message} />
        </>
    )
}
