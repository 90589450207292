import React from 'react'
import ReactDOM from 'react-dom'
import './main.css'
import '@/style/main.css'
import App from './App.jsx'
import './polyfill.mjs'

ReactDOM.render(
    <React.StrictMode>
            <App />
    </React.StrictMode>,

    document.getElementById('root')
)
