import DiagramEditor from '../../components/DiagramEditor/DiagramEditor'
import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import runAsync from "@/script/runAsync.mjs";
import useHTTP from "@/hooks/useHTTP.mjs";
import useDocTitle from "@/hooks/useDocTitle.mjs";

export default function FlowDiagram() {
    const [searchParams, setSearchParams] = useSearchParams()
    const flowId = searchParams.get('flowId')
    const flowName = searchParams.get('flowName')
    const http = useHTTP();
    const [basFlowData, setBasFlowData] = useState()
    const [data, setData] = useState([])

    useDocTitle([flowName, "流程图编辑器"])

    const handleDiagramEditorSave = async ({loading, setLoading, isCloseWindow, data, snapshot}) => {
        try {
            console.log(snapshot)
            setLoading(true)
            await runAsync(
                async () => {
                    return await http.put("/basFlowDatas", {
                        pkid: flowId,
                        data,
                    })
                },
                {
                    error: {content: (err) => `保存流程图失败: ${err.message}`},
                    loading: {show: true},
                    success: {show: true},
                }
            );
            if (isCloseWindow) {
                window.close();
            }
        } finally {
            setLoading(false)
        }
    }

    const doQuery = async () => {
        const basFlowData = await runAsync(
            async () => {
                return await http.get("/basFlowData", {
                    pkid: flowId,
                })
            },
            {
                error: {content: (err) => `查询流程图失败: ${err.message}`},
                loading: {show: false},
                success: {show: false},
            }
        );

        if (basFlowData) {
            if (basFlowData.data) {
                basFlowData.data = JSON.parse(basFlowData.data)
                setData(basFlowData.data)
            }
            setBasFlowData(basFlowData);
        }
    }

    useEffect(() => {
        if (flowId) {
            doQuery()
        } else {
            window.close()
        }
    }, []);

    return (
        <DiagramEditor data={data} onSave={handleDiagramEditorSave}/>
    )
}
