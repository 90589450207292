import FlowEditor from './FlowEditor'
import {redirect} from 'react-router-dom'
import {useUser} from '@/biz/user.mjs'
import TestFlowEditor from "@/pages/FlowEditor/TestFlowEditor";
export default () => {
    const user = useUser()

    const loader = ({request: {url}}) => {
        const {pathname, search} = new URL(url)
        if (!user) {
            if ('/' !== pathname) {
                const r = encodeURIComponent(pathname + search)
                return redirect(`/login?r=${r}`)
            }
            else {
                return redirect('/login')
            }
        } else {
            return null
        }
    }

    return {
        path: '/flowEditor/test',
        element: <TestFlowEditor />,
        loader,
    }
}
