import LoginPage from './LoginPage.jsx'
import {redirect} from 'react-router-dom'
import {useUser} from '@/biz/user.mjs'

export default () => {
    const user = useUser()

    const loader = ({request: {url}}) => {
        if (user) {
            const {searchParams} = new URL(url)
            const r = searchParams.get('r')
            return redirect(r ?? '/')
        }
        else {
            return null
        }
    }

    return {
        path: '/login',
        element: <LoginPage />,
        loader,
    }
}
