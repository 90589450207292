import React, {Component, useEffect, useRef} from 'react';
import Prism from 'prismjs';
import 'prismjs/themes/prism.css';
import 'prismjs/plugins/line-numbers/prism-line-numbers';
import 'prismjs/plugins/line-numbers/prism-line-numbers.css'
import 'prismjs/components/prism-java'; // for java
import 'prismjs/components/prism-python'; // for python
import 'prismjs/components/prism-markup'; // for python
import 'prismjs/components/prism-yaml'; // for python
import 'prismjs/components/prism-ignore'; // for python
import 'prismjs/components/prism-docker'; // for python
import 'prismjs/components/prism-sql'; // for python

import mapping from './languageMapping.mjs'

const analyseLanguage = ({name, content}) => {
    let lang;
    if (name.startsWith(".") || name.indexOf(".") === -1) {
        lang = mapping[name];
    } else {
        const names = name.split(".");
        lang = mapping[names[names.length - 1]];
    }
    console.log('lang', lang)
    return lang ?? 'markup'
}

const PrismHighlighter = ({name, content, plugins, ...props}) => {
    const language = analyseLanguage({name, content});
    const codeRef = useRef(null)

    useEffect(() => {
        if (content && codeRef) {
            Prism.highlightElement(codeRef.current)
        }
    }, [content]);

    return (
        <pre className="line-numbers" style={{flexGrow: 1}} {...props}>
                <code ref={codeRef} className={`language-${language}`}>
                    {content}
                </code>
        </pre>
    );
}

export default PrismHighlighter;
