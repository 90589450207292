import React from 'react';
import { Select, Button } from 'antd';

const Pane = ({
    sf, sid, sname, rmSfs, sfIndex, chgSf, nextSf, openSf, setVisibleSf,
    scene, scenes, sceneIndex, chgScene, nextScene,
    oprId, sfOprs, oprFlag, chgOpr, doOpr,
}) => {
    const cssTd = {
        borderRight: '30px solid #fff'
    }

    const cssWidth = {
        width: '20vw',
    }

    const cssJustify = {
        display: 'flex',
        justifyContent: 'space-between',
    }

    const cssFont = {
        fontSize: '12pt',
    }

    return (
        <div>
            <table>
                <tr>
                    <td css={cssTd}>
                        <div css={[cssWidth, cssJustify]}>
                            <span css={cssFont}>{sf?.ucFlag ? '用例' : '功能'}：</span>

                            <Button size="small" type="primary" shape="round" ghost onClick={() => setVisibleSf(true)}>加载</Button>
                        </div>
                    </td>

                    <td css={cssTd}>
                        <div css={[cssWidth, cssJustify]}>
                            <span css={cssFont}>{sf?.ucFlag ? '作业' : '场景'}：</span>

                            <Button size="small" type="primary" shape="round" ghost
                                disabled={scenes.length == 0}
                                onClick={() => {
                                    let mapPath = '/' + sname + '/';
                                    if (scene == '主场景' || sf?.ucFlag) {
                                        mapPath = mapPath + scene + '/';
                                    } else {
                                        mapPath = mapPath + '其他场景/' + scene + '/';
                                    }
                                    openSf(mapPath);
                                }}
                            >打开</Button>
                        </div>
                    </td>

                    <td>
                        <div css={cssJustify}>
                            <span css={cssFont}>操作：</span>

                            <div>
                                <Button size="small" type="primary" shape="round" ghost style={{ marginLeft: '5px' }} onClick={doOpr} disabled={sfOprs.length == 0 || oprFlag}>执行</Button>
                                <Button size="small" type="primary" shape="round" ghost style={{ marginLeft: '5px' }} onClick={nextScene} disabled={scenes.length == 0 || sceneIndex == (scenes.length - 1)}>下一{sf?.ucFlag ? '作业' : '场景'}</Button>
                                <Button size="small" type="primary" shape="round" ghost style={{ marginLeft: '5px' }} onClick={nextSf} disabled={rmSfs.length == 0 || sfIndex == (rmSfs.length - 1)}>下一{sf?.ucFlag ? '用例' : '功能'}</Button>
                            </div>
                        </div>
                    </td>
                </tr>

                <tr>
                    <td css={cssTd}>
                        <Select size="middle" style={{ width: '20vw' }} value={sid} onChange={(e, option) => chgSf(e, option.children)}>
                            {
                                rmSfs.map(v => <Option value={v.pkid}>{v.sfName}</Option>)
                            }
                        </Select>
                    </td>

                    <td css={cssTd}>
                        <Select size="middle" style={{ width: '20vw' }} value={scene} onChange={e => chgScene(e)}>
                            {
                                scenes.map(v => <Option value={v}>{v}</Option>)
                            }
                        </Select>
                    </td>

                    <td>
                        <Select size="middle" style={{ width: '40vw' }} value={oprId} onChange={(e, option) => chgOpr(e, option.children)}>
                            {
                                sfOprs.map((v, index) => {
                                    const info = (index + 1) + '. ' + v.oprTitle;
                                    return <Option value={v.pkid}>{info}</Option>;
                                })
                            }
                        </Select>
                    </td>
                </tr>
            </table>
        </div>
    );
}

export default Pane;