export default ({...props}) => (
    <svg className="svg-node" {...props}>
        <g transform="translate(0.5,0.5)" style={{visibility: "visible"}}>
            <rect
                x="2.38"
                y="1.36"
                width="27.2"
                height="27.2"
                rx="3.16"
                ry="3.16"
                fill="rgb(218, 232, 252)"
                stroke="rgb(108, 142, 191)"
                stroke-width="1.3"
                pointer-events="all"
            ></rect>
        </g>
    </svg>
)
