import {useEffect} from 'react'

export default () => {
    useEffect(
        () => {
            const key = import.meta.env.VITE_BAIDU_

            if (! key) {
                return
            }

            window._hmt ??= []
            const hm = document.createElement('script')
            hm.src = `https://hm.baidu.com/hm.js?${key}`
            const s = document.getElementsByTagName('script')[0]
            s.parentNode.insertBefore(hm, s)
        },

        []
    )
}
