import {css} from "@emotion/react";
import {useRef, useState, useEffect, useMemo} from 'react';
import {Button, Form, Input, Space, Typography, Tree} from 'antd';
import {FileTextTwoTone, FolderTwoTone} from '@ant-design/icons';
import {nanoid} from "nanoid";
import {useSearchParams} from "react-router-dom";
import useHTTP from "@/hooks/useHTTP.mjs";
import runAsync from "@/script/runAsync.mjs";
import {writeFileToDisk} from "@/script/file.mjs";
import {useOpenModal} from "@/components/Modal/Modal";
import MessageModal from "@/pages/ApiIDE/MessageModal";

const {Text} = Typography
const {DirectoryTree} = Tree

const cssDirectory = css({
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    overflow: 'hidden',
    // borderRight: '1px solid #f0f0f0',
    width: 500,
    borderRight: '1px solid rgb(255,255,255,0.65)',
    // borderRight: '1.5px solid #0099CC',
    borderImage: 'linear-gradient(to bottom, #8f41e9, #0099CC, #8f41e9) 1'
})

const cssDirectoryHeader = css({
    height: 36,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexShrink: 0,
    borderBottom: '1px solid #f0f0f0',
    borderImage: 'linear-gradient(to right, #8f41e9, #0099CC, #8f41e9) 1',
    padding: 12,
    fontWeight: 'bold',
})

const cssDirectoryFooter = css({
    height: 36,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexShrink: 0,
    borderTop: '1px solid #f0f0f0',
    borderImage: 'linear-gradient(to right, #8f41e9, #0099CC, #8f41e9) 1',
    padding: 12,
    fontWeight: 'bold',
})

const cssDirectoryContent = css({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'hidden',
    padding: 4,
})

const cssTree = css({
    backgroundColor: '#28262b',
    color: 'rgb(255,255,255,0.65)',
    '& .ant-tree-treenode:hover': {
        // background: '#f0f0f0',
        color: 'rgba(0,153,204,0.8)'
    },
    '& .ant-tree-switcher': {
        background: 'transparent',

    },
    '& .ant-tree-node-content-wrapper': {
        whiteSpace: 'nowrap',
    }
})


const MessageToolBar = ({data}) => {
    const openModal = useOpenModal()

    return useMemo(() => {
        const css = {
            cursor: 'pointer'
        }
        return (
            <>
                {
                    data &&
                    (
                        <Space>
                            <Text
                                onClick={() => {
                                    if (data.warningMessage) {
                                        openModal(<MessageModal title="警告信息" message={data.warningMessage}/>);
                                    }
                                }}
                                css={css}
                                type="warning"
                                disabled={!data.warningMessage}
                            >
                                警告信息
                            </Text>
                            <Text
                                onClick={() => {
                                    if (data.errorMessage) {
                                        openModal(<MessageModal title="错误信息" message={data.errorMessage}/>)
                                    }
                                }}
                                css={css}
                                type="danger"
                                disabled={!data.errorMessage}
                            >
                                错误信息
                            </Text>
                            <Text
                                onClick={() => openModal(<MessageModal title="耗时" message={data.stopWatchMessage}/>)}
                                css={css}
                                type="success"
                            >
                                耗时
                            </Text>
                        </Space>
                    )
                }
            </>
        )
    }, [data])
}

const Directory = ({openFile, onSelect, ...props}) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const fmFcId = searchParams.get('fmFcId')
    const fmFcName = searchParams.get('fmFcName')
    const fmFcUserCode = searchParams.get('fmFcUserCode')

    const http = useHTTP();
    //表格 Loading 控制变量
    const [loading, setLoading] = useState(false);
    //树控件需要的数据结构
    const divRef = useRef(null)
    const [data, setData] = useState(null)
    const [treeHeight, setTreeHeight] = useState(0);
    const [treeData, setTreeData] = useState([]);
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [autoExpandParent, setAutoExpandParent] = useState(true);
    const onExpand = (expandedKeysValue) => {
        setExpandedKeys(expandedKeysValue);
        setAutoExpandParent(false);
    };

    const doQuery = async () => {
        setLoading(true)
        try {
            const resultData = await runAsync(
                async () => {
                    return await http.get(`/getSsAgApiGeneratedRecord`, {fmFcId, fmFcName, fmFcUserCode,});
                }, {
                    error: {content: (err) => `查询失败: ${err.message}`},
                    loading: {show: true},
                    success: {show: false},
                }
            );
            if (resultData) {
                const {records, ...props} = resultData;
                const treeData = []
                if (records) {
                    records.forEach(({type, name, dir, fileContent, ...props}) => {
                        const treeNode = treeData.find(d => d.key == dir);
                        if (treeNode) {
                            treeNode.children.push({
                                fileType: type,
                                name,
                                dir,
                                fileContent,
                                ...props,
                                key: dir + "/" + name,
                                pKey: dir,
                                title: name,
                                icon: <FileTextTwoTone twoToneColor='#52c41a'/>,
                                type: 'file'
                            })
                        } else {
                            treeData.push({
                                key: dir,
                                pKey: null,
                                title: dir,
                                icon: <FileTextTwoTone twoToneColor='#52c41a'/>,
                                type: 'dir',
                                children: [
                                    {
                                        fileType: type,
                                        name,
                                        dir,
                                        fileContent,
                                        ...props,
                                        key: dir + "/" + name,
                                        pKey: dir,
                                        title: name,
                                        icon: <FileTextTwoTone twoToneColor='#52c41a'/>,
                                        type: 'file'
                                    }
                                ]
                            })
                        }
                    })
                }

                setTreeData(treeData)
                setExpandedKeys(treeData.map(({key}) => key))
                setData(resultData)
            }
        } finally {
            setLoading(false)
        }
    }

    const doDownload = async () => {
        setLoading(true)
        try {
            const {blob, fileName} = await runAsync(async () => {
                return await http.post(`/downloadSsAgApiGeneratedRecord`, {fmFcId, fmFcName, fmFcUserCode,});
            }, {
                error: {content: (err) => `下载失败: ${err.message}`},
                loading: {show: false},
                success: {show: false},
            });

            writeFileToDisk(blob, {suggestedName: fileName})
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        (async () => {
            setTreeHeight(divRef.current.clientHeight)
            doQuery();
        })()
    }, []);

    return (
        <div css={cssDirectory} {...props}>
            <div css={cssDirectoryHeader}>
                {fmFcName}({fmFcUserCode})
                <a onClick={doDownload}>打包下载</a>
            </div>
            <div ref={divRef}  css={cssDirectoryContent}>
                {
                    treeData.length > 0 &&
                    <DirectoryTree
                        css={cssTree}
                        height={treeHeight}
                        // motion={null}
                        defaultExpandAll={true}
                        showLine={{showLeafIcon: false,}}
                        showIcon={true}
                        multiple={false}
                        onSelect={(selectedKeys, {selected, selectedNodes, node, event}) => {
                            console.log(selectedNodes[0])
                            if (selected && onSelect && selectedNodes[0].type === 'file') {
                                onSelect(selectedNodes[0])
                            }
                        }}
                        onExpand={onExpand}
                        expandedKeys={expandedKeys}
                        autoExpandParent={autoExpandParent}
                        treeData={treeData}
                    />
                }
            </div>
            <div css={cssDirectoryFooter}>
                <MessageToolBar data={data}/>
            </div>
        </div>
    )
}

export default Directory;
