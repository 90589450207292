import {css} from "@emotion/react";
import IconCirCle from "@/components/DiagramEditor/icons/Circle";
import IconRect from "@/components/DiagramEditor/icons/Rect";
import IconRectRadius from "@/components/DiagramEditor/icons/RectRadius";
import IconTriangle from "@/components/DiagramEditor/icons/Triangle";
import IconEllipse from "@/components/DiagramEditor/icons/Ellipse";
import IconDiamond from "@/components/DiagramEditor/icons/Diamond";
import IconCylinde from "@/components/DiagramEditor/icons/Cylinde";
import IconActor from "@/components/DiagramEditor/icons/Actor";
import IconParallelogram from "@/components/DiagramEditor/icons/Parallelogram";
import IconText from "@/components/DiagramEditor/icons/Text";
import IconLeftArrow from "@/components/DiagramEditor/icons/LeftArrow";
import IconRightArrow from "@/components/DiagramEditor/icons/RightArrow";
import IconHorizontalArrow from "@/components/DiagramEditor/icons/HorizontalArrow";
import IconUpArrow from "@/components/DiagramEditor/icons/UpArrow";
import IconDownArrow from "@/components/DiagramEditor/icons/DownArrow";
import IconVerticalArrow from "@/components/DiagramEditor/icons/VerticalArrow";
import IconPentagon from "@/components/DiagramEditor/icons/Pentagon";
import IconHexagon from "@/components/DiagramEditor/icons/Hexagon";
import IconSeptagon from "@/components/DiagramEditor/icons/Septagon";
import IconHeptagon from "@/components/DiagramEditor/icons/Heptagon";
import IconTrapezoid from "@/components/DiagramEditor/icons/Trapezoid";
import IconCross from "@/components/DiagramEditor/icons/Cross";
import IconMinus from "@/components/DiagramEditor/icons/Minus";
import IconTimes from "@/components/DiagramEditor/icons/Times";
import IconDivide from "@/components/DiagramEditor/icons/Divide";
import IconStartFlow from "@/components/DiagramEditor/icons/StartFlow";
import IconEndFlow from "@/components/DiagramEditor/icons/EndFlow";
import IconSubFlow from "@/components/DiagramEditor/icons/SubFlow";
import IconOnlineData from "@/components/DiagramEditor/icons/OnlineData";
import IconOfflineData from "@/components/DiagramEditor/icons/OfflineData";
import IconOnlineJob from "@/components/DiagramEditor/icons/OnlineJob";
import IconOfflineJob from "@/components/DiagramEditor/icons/OfflineJob";
import {cloneElement} from "react";
import {useDiagramEditorContext} from "@/components/DiagramEditor/DiagramEditor";

const cssDiagramSider = css({
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    userSelect: 'none',
    // width: 185,
    // height: 'calc(100% - 40px)',
    borderRight: '1px solid #dadce0',
    // padding: 10,
    background: '#f0f0f0',
    overflow: 'auto',
    '& .node-category-title': {
        fontSize: 12,
        fontWeight: 'bold',
        // padding: 8,
        borderBottom: '1px solid #dadce0',
        height: 42,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    '& .node-list': {
        padding: 8,
        borderBottom: '1px solid #dadce0',
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
        // flexWrap: 'wrap',
        '& .node': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            fontSize: 10,
            cursor: 'pointer',
            '& .svg-node': {
                width: 31,
                height: 30,
            }
        },
    }
})
//通用图标
const generalIcons = [
    {
        type: 'pro-circle',
        Element: <IconCirCle/>,
    },
    {
        type: 'pro-rect',
        Element: <IconRect/>,
    },
    {
        type: 'rect-radius',
        Element: <IconRectRadius/>,
    },
    {
        type: 'triangle',
        Element: <IconTriangle/>,
    },
    {
        type: 'pro-ellipse',
        Element: <IconEllipse/>,
    },
    {
        type: 'pro-diamond',
        Element: <IconDiamond/>,
    },
    {
        type: 'cylinde',
        Element: <IconCylinde/>,
    },
    {
        type: 'actor',
        Element: <IconActor/>,
    },
    {
        type: 'parallelogram',
        Element: <IconParallelogram/>,
    },
    {
        type: 'pro-text',
        Element: <IconText/>,
    },
    {
        type: 'left-arrow',
        Element: <IconLeftArrow/>,
    },
    {
        type: 'right-arrow',
        Element: <IconRightArrow/>,
    },
    {
        type: 'horizontal-arrow',
        Element: <IconHorizontalArrow/>,
    },
    {
        type: 'up-arrow',
        Element: <IconUpArrow/>,
    },
    {
        type: 'down-arrow',
        Element: <IconDownArrow/>,
    },
    {
        type: 'vertical-arrow',
        Element: <IconVerticalArrow/>,
    },
    // {
    //     type: 'star',
    //     Element: <IconStar/>,
    // },
    {
        type: 'pentagon',
        Element: <IconPentagon/>,
    },
    {
        type: 'hexagon',
        Element: <IconHexagon/>,
    },
    {
        type: 'septagon',
        Element: <IconSeptagon/>,
    },
    {
        type: 'heptagon',
        Element: <IconHeptagon/>,
    },
    {
        type: 'trapezoid',
        Element: <IconTrapezoid/>,
    },
    {
        type: 'cross',
        Element: <IconCross/>,
    },
    {
        type: 'minus',
        Element: <IconMinus/>,
    },
    {
        type: 'times',
        Element: <IconTimes/>,
    },
    {
        type: 'divide',
        Element: <IconDivide/>,
    },
]

const businIcons = [
    {
        type: 'busin-startflow',
        Element: <IconStartFlow/>,
        title: '开始'
    },
    {
        type: 'busin-endflow',
        Element: <IconEndFlow/>,
        title: '结束'
    },
    {
        type: 'busin-onlinejob',
        Element: <IconOnlineJob/>,
        title: '线上作业'
    },
    {
        type: 'busin-offlinejob',
        Element: <IconOfflineJob/>,
        title: '线下作业'
    },
    {
        type: 'busin-onlinedata',
        Element: <IconOnlineData/>,
        title: '线上数据'
    },
    {
        type: 'busin-offlinedata',
        Element: <IconOfflineData/>,
        title: '线下数据'
    },
    {
        type: 'busin-subflow',
        Element: <IconSubFlow/>,
        title: '子流程'
    },
    {
        type: 'pro-diamond',
        Element: <IconDiamond/>,
        title: '判断条件'
    },
    {
        type: 'pro-text',
        Element: <IconText/>,
        title: '文本'
    },
]
export default ({lf}) => {
    const handleDndNode = (type) => {
        lf.dnd.startDrag({
            type
        })
    }

    return (
        <div css={[cssDiagramSider]}>
            <div className='node-category-title'>图形</div>
            <div className='node-list'>
                {
                    businIcons.map(({type, Element, title}) => (
                        <div className="node" onMouseDown={() => handleDndNode(type)} title={title}>
                            {
                                cloneElement(Element, {className: 'svg-node'})
                            }
                            <span>{title}</span>
                        </div>
                    ))
                }
            </div>
            {/*<div className='node-category-title'>通用流程图</div>*/}
            {/*<div className='node-list'>*/}
            {/*    {*/}
            {/*        generalIcons.map(({type, Element}) => (*/}
            {/*            <div className="node" onMouseDown={() => onDndNode(type)}>*/}
            {/*                {*/}
            {/*                    cloneElement(Element, {className: 'svg-node'})*/}
            {/*                }*/}
            {/*            </div>*/}
            {/*        ))*/}
            {/*    }*/}
            {/*</div>*/}
        </div>
    )
}
