import {css} from '@emotion/react'
import Logo from '@/components/Logo/Logo.jsx'

const cssFooter = css({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '4px 8px',
})

const cssLogo = css({
    width: '1em',
    height: '1em',
})

const cssProduct = css({
    margin: '0 1em',
})

const Footer = (props) => {
    return (
        <div
            css={cssFooter}
            {...props}
        >
            <Logo css={cssLogo} />
            <span css={cssProduct}>软件逻辑地图</span>
            <a href="https://www.softwaremap.cn/">www.softwaremap.cn</a>
        </div>
    )
}

export default Footer
