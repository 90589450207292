import React from 'react';
import { Select, Button, Input } from 'antd';

const Pane = ({
    sid, sname, rmSfs, sfIndex, chgSf, nextSf, openSf, setVisibleSf,
    scene, scenes, sceneIndex, chgScene, nextScene,
    oprId, sfOprs, oprFlag, chgOpr, doOpr,
    bname, openBf, setVisibleBf,
    bjId, bjName, bmBjs, chgBj, nextBj,
}) => {
    const cssTd = {
        width: '33vw',
    }

    return (
        <div style={{ padding: '0px 0px 0px 20px' }}>
            <table>
                <tr>
                    <td css={cssTd}>
                        <span>流程：</span>
                        <Input size="middle" style={{ width: '20vw' }} value={bname} readOnly />

                        <Button size="small" type="primary" shape="round" style={{ marginLeft: '5px' }} ghost onClick={() => setVisibleBf(true)}>加载</Button>
                    </td>

                    <td css={cssTd}>
                        <span>作业：</span>
                        <Select size="middle" style={{ width: '20vw' }} value={bjId} onChange={(e, option) => chgBj(e, option.children)}>
                            {
                                bmBjs.map(v => <Option value={v.pkid}>{v.bjName}</Option>)
                            }
                        </Select>

                        <Button size="small" type="primary" shape="round" style={{ marginLeft: '5px' }} ghost disabled={bmBjs.length == 0}
                            onClick={() => {
                                openBf('/' + bname + '/业务作业/' + bjName + '/');
                            }}
                        >打开</Button>
                    </td>

                    <td css={cssTd}>
                        <span>功能：</span>
                        <Select size="middle" style={{ width: '20vw' }} value={sid} onChange={(e, option) => chgSf(e, option.children)}>
                            {
                                rmSfs.map(v => <Option value={v.pkid}>{v.sfName}</Option>)
                            }
                        </Select>

                        <Button size="small" type="primary" shape="round" ghost style={{ marginLeft: '5px' }} disabled={rmSfs.length == 0}
                            onClick={openSf}
                        >打开</Button>
                    </td>
                </tr>

                <tr>
                    <td colSpan={3}>
                        <span>操作：</span>
                        <Select size="middle" style={{ width: '48vw' }} value={oprId} onChange={(e, option) => chgOpr(e, option.children)}>
                            {
                                sfOprs.map((v, index) => {
                                    const info = (index + 1) + '. ' + v.oprTitle;
                                    return <Option value={v.pkid}>{info}</Option>;
                                })
                            }
                        </Select>

                        <Button size="small" type="primary" shape="round" ghost style={{ marginLeft: '5px' }} onClick={doOpr} disabled={sfOprs.length == 0 || oprFlag}>执行</Button>
                        <Button size="small" type="primary" shape="round" ghost style={{ marginLeft: '5px' }} onClick={nextSf} disabled={rmSfs.length == 0 || sfIndex == (rmSfs.length - 1)}>下一功能</Button>
                        <Button size="small" type="primary" shape="round" ghost style={{ marginLeft: '5px' }} onClick={nextBj} disabled={bmBjs.length == 0}>下一作业</Button>
                    </td>
                </tr>
            </table>
        </div>
    );
}

export default Pane;