import 'antd/dist/antd.less'
import React, { useEffect, useState } from 'react'
import { PreviewWidget } from '@/pages/MainPage/frames/FormilyMobile/widgets'
import {
  transformToTreeNode,
} from '@designable/formily-transformer'
import './styles.less'

const FormilyPreviewMobile = (props) => {
  const [iframeProps, setIframeProps] = useState({})

  useEffect(() => {
    window.addEventListener("message", handleReportEvent);
    return () => {
      window.removeEventListener("message", handleReportEvent);
    };
  }, []);

  const handleReportEvent = (event) => {
    setIframeProps(transformToTreeNode(JSON.parse(event.data)))
  };

  // const params = new URLSearchParams(location.search.split('?')[1]);
  
  // const jsonObject = {};
  // for (const [key, value] of params.entries()) {
  //   jsonObject[key] = value;
  // }
  // const uvId = params.get("uvId"); 
  // const openUiDetail = () => {
  //   openWindow('/UiDetail', { uiId: shcemaData.uiId, uiName: shcemaData.uiName });
  // }

  // const toggleExpansion = () => {
  //   setIsExpanded(!isExpanded);
  // };

  // console.log(window.innerWidth < 768)

  // useEffect(async() => {
  //   const data = await schemaService.loadSchema(undefined, uvId)
  //   setSchemaData(data || {});
  // }, [])
  // const tree = useMemo(() => {return shcemaData && shcemaData.uvMap ? transformToTreeNode(JSON.parse(shcemaData.uvMap)): transformToTreeNode('')}, [shcemaData])
  return (
    <section className='formilyMobileRoot'>
      <PreviewWidget tree={iframeProps} />
    </section>
  )
}

export default FormilyPreviewMobile;
