export default ({...props}) => (
    <svg className="svg-node" {...props}>
        <g transform="translate(0.5,0.5)" style={{visibility: "visible"}}>
            <path
                d="M 1.44 22.08 L 6.24 7.68 L 30.24 7.68 L 25.44 22.08 Z"
                fill="rgb(245, 245, 245)"
                stroke="rgb(102, 102, 102)"
                stroke-width="1.3"
                stroke-miterlimit="10"
                pointer-events="all"
            ></path>
        </g>
    </svg>
)
