import LogicFlow from '@logicflow/core'
import {SelectionSelect, Snapshot} from '@logicflow/extension'
import '@logicflow/core/dist/style/index.css'
import '@logicflow/extension/lib/style/index.css'
import React, {useEffect} from "react";
import {css} from "@emotion/react";
import {registerCustomNode} from "@/components/DiagramEditor/node/registerCustomNode.mjs";
import DiagramToolbar from "./DiagramToolbar";

const cssDiagramContainer = css({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1',
    '& .editor': {
        display: 'flex',
        flexGrow: '1',
    }
})

export default ({
                    lf,
                    setLf,
                    activeEdges,
                    setActiveEdges,
                    activeNodes,
                    setActiveNodes,
                    setElementProperty,
                    data,
                    onSave,
                    refContainer,
                }) => {
    useEffect(() => {
        LogicFlow.use(Snapshot);
        LogicFlow.use(SelectionSelect);
        // LogicFlow.use(MiniMap);

        const logicflow = new LogicFlow({
            container: refContainer.current,
            width: refContainer.current.clientWidth,
            height: refContainer.current.clientHeight,
            overlapMode: 1,
            // autoWrap: true,
            animation: true,
            adjustEdgeStartAndEnd: true,
            metaKeyMultipleSelected: true,
            multipleSelectKey: 'meta',
            keyboard: {
                enabled: true,
                shortcuts: [
                    {
                        keys: ["delete"],
                        callback: () => {
                            const elements = logicflow.getSelectElements(true);
                            logicflow.clearSelectElements();
                            elements.edges.forEach((edge) => logicflow.deleteEdge(edge.id));
                            elements.nodes.forEach((node) => logicflow.deleteNode(node.id));
                        },
                    },
                ],
            },
            grid: {
                visible: false,
                size: 5
            },
            background: {
                backgroundImage: 'url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PHBhdHRlcm4gaWQ9ImdyaWQiIHdpZHRoPSI0MCIgaGVpZ2h0PSI0MCIgcGF0dGVyblVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+PHBhdGggZD0iTSAwIDEwIEwgNDAgMTAgTSAxMCAwIEwgMTAgNDAgTSAwIDIwIEwgNDAgMjAgTSAyMCAwIEwgMjAgNDAgTSAwIDMwIEwgNDAgMzAgTSAzMCAwIEwgMzAgNDAiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2QwZDBkMCIgb3BhY2l0eT0iMC4yIiBzdHJva2Utd2lkdGg9IjEiLz48cGF0aCBkPSJNIDQwIDAgTCAwIDAgMCA0MCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZDBkMGQwIiBzdHJva2Utd2lkdGg9IjEiLz48L3BhdHRlcm4+PC9kZWZzPjxyZWN0IHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JpZCkiLz48L3N2Zz4=")',
                backgroundRepeat: 'repeat'
            }
        });

        logicflow.setTheme(
            {
                baseEdge: {strokeWidth: 1},
                baseNode: {strokeWidth: 1},
                nodeText: {overflowMode: 'autoWrap', lineHeight: 1.5},
                edgeText: {overflowMode: 'autoWrap', lineHeight: 1.5}
            }
        )
        registerCustomNode(logicflow)

        logicflow.setDefaultEdgeType('pro-polyline')

        logicflow.render();

        // logicflow.extension.miniMap.show(0, 0)

        logicflow.on('selection:selected,node:click,blank:click,edge:click', () => {
            //使用setTimeout把事件加入到队列末尾执行，防止getSelectElements获取不到数据
            setTimeout(()=> {
                const {nodes, edges} = logicflow.getSelectElements()
                setActiveEdges(edges)
                setActiveNodes(nodes)
                let properties = {}
                nodes.forEach(node => {
                    properties = {...properties, ...node.properties}
                })
                edges.forEach(edge => {
                    properties = {...properties, ...edge.properties}
                })
                setElementProperty(properties)
            }, 10)
        })

        setLf(logicflow);
    }, []);

    useEffect(() => {
        if (data) {
            if (lf) {
                lf.render(data)
            }
        }
    }, [data]);

    return (
        <div css={[cssDiagramContainer]}>
            <DiagramToolbar onSave={onSave} lf={lf} activeEdges={activeEdges}/>
            <main className='editor' ref={refContainer}/>
        </div>
    )
}
