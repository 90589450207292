import FormilyPreview from './FormilyPreview.jsx';
import FormilyMobile from './FormilyMobile.jsx'
import FormilyPreviewMobile from './FormilyPreviewMobile.jsx'

export default () => {
    return [{
        path: '/FormilyPreview',
        element: <FormilyPreview />,
    },
    {
        path: '/FormilyMobile',
        element: <FormilyMobile />,
    },{
        path: 'FormilyPreviewMobile',
        element:<FormilyPreviewMobile/>,
    }]
}