import "antd/dist/antd.less";
import React, { useMemo, useEffect, useState, useRef } from "react";
import { Breadcrumb, Button, Spin, Modal, Divider, Select } from "antd";
import { PreviewWidget } from "@/pages/MainPage/frames/Formily/widgets";
import Iframe from "@/pages/MainPage/frames/FormilyMobile/FormilyIframe";
import { useSchemaService } from "@/pages/MainPage/frames/Formily/service";
import { transformToTreeNode } from "@designable/formily-transformer";
import openWindow from "@/script/openWindow.mjs";
import useApi from "./useApi.mjs";
// @ts-ignore
import SoftwareMap from "@/components/SoftwareMap/SoftwareMap.jsx";
import "./styles.less";
import { message, Layout } from "antd";
import { Link } from "react-router-dom";
import { css } from "@emotion/react";
import favicon from "/favicon.png";
import { DesktopOutlined } from "@ant-design/icons";

// import ShcemaDataPreview from './schemaDataPreview'

const cssLayoutHeader = css({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: 0,
});
const cssLayoutHeaderLeft = css({
  fontSize: 20,
  color: "#fff",
  padding: "0 15px",
  display: "flex",
  alignItems: "center",
  flexGrow: "1",
  flexShrink: "0",
  flexBasis: 0,
});
const cssLayoutHeaderCenter = css({
  fontSize: 14,
  fontWeight: "bold",
  color: "#fff",
  padding: "0 15px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexGrow: "1",
  flexShrink: "0",
  flexBasis: 0,
  gap: 12,
});
const cssLayoutHeaderRight = css({
  color: "#fff",
  padding: "0 15px",
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  flexGrow: "1",
  flexShrink: "0",
  flexBasis: 0,
  gap: 12,
});
const cssLink = css({
  fontWeight: "bold",
  color: "#fff",
  display: "flex",
  alignItems: "center",
});
const cssLogo = css({
  height: 22,
  marginRight: 8,
});
const cssDivider = css({
  borderColor: "#fff",
});
const cssRibbonButton = css({
  fontSize: 14,
  fontWeight: "bold",
  color: "#fff",
  "&:hover": {
    color: "#0099CC",
  },
});
const Header = ({ isTemplate, shcemaData, setScaleNum }) => {
  const handleChange = (val) => {
    setScaleNum(val);
  };

  // const fileId = shcemaData?.uvMap && JSON.parse(shcemaData?.uvMap || '') ? JSON.parse(shcemaData.uvMap).fileId : undefined;
  const fileIdData = shcemaData?.demoMap && JSON.parse(shcemaData?.demoMap) ? JSON.parse(shcemaData?.demoMap || '')[0] : undefined
  const fileId = fileIdData?.data?.fileId;
  const openUiDetail = () => {
    openWindow("/UiDetail", {
      uiId: shcemaData.uiId,
      uiName: shcemaData.uiName,
    });
  };

  const exportImage = async () => {    
    if(fileId) {
      window.open(
        `${import.meta.env.VITE_HTTP_BASE_URL}/files/${fileId}`,
        '_blank'
      )
    }
}

  return (
    <Layout.Header css={cssLayoutHeader}>
      <div css={cssLayoutHeaderLeft}>
        <Link css={cssLink} reloadDocument to="/" title={"进入工作台"}>
          <img css={cssLogo} alt="logo" src={favicon} />
          软件逻辑地图
        </Link>
        <Divider type="vertical" css={cssDivider} />
        <span style={{ fontSize: 14, fontWeight: "bold" }}>在线预览</span>
      </div>

      <div css={cssLayoutHeaderCenter}>
        <DesktopOutlined />
        {shcemaData.uvName}
        <Select
          defaultValue={1}
          bordered={false}
          style={{ color: "#fff", width: 80 }}
          showArrow={false}
          size="small"
          onChange={handleChange}
          options={[
            {
              value: 0.35,
              label: "35%",
            },
            {
              value: 0.5,
              label: "50%",
            },
            {
              value: 0.8,
              label: "80%",
            },
            {
              value: 1,
              label: "100%",
            },
            {
              value: 1.2,
              label: "120%",
            },
            {
              value: 1.5,
              label: "150%",
            },
            {
              value: 2,
              label: "200%",
            },
          ]}
        />
      </div>

      <div css={cssLayoutHeaderRight}>
        {!isTemplate && (
          <>
            <a onClick={openUiDetail} css={cssRibbonButton}>
              查看用户界面
            </a>
            {fileId && <a onClick={exportImage} css={cssRibbonButton}>导出图片</a>}
          </>
        )}
      </div>
    </Layout.Header>
  );
};

const FormilyPreview = (props) => {
  const params = new URLSearchParams(location.search.split("?")[1]);
  const uvMode =  params.get("uvMode") ? Number(params.get("uvMode")) : 2;
  const isTemplate = uvMode != 2;
  const schemaService = useSchemaService(uvMode);
  const [shcemaData, setSchemaData] = useState({});
  const [mapData, setMapData] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [scaleNum, setScaleNum] = useState(1);
  const [uvId, setUvId] = useState(params.get("uvId"));
  const [historyIdList, setHistoryIdList] = useState([params.get("uvId")]);
  const iframeRef = useRef(null);
  const api = useApi();

  const jsonObject = {};
  for (const [key, value] of params.entries()) {
    jsonObject[key] = value;
  }

  // 添加一个新的状态到历史栈顶
  const pushToHistory = (id) => {
    if (historyIdList.length > 9) {
      setHistoryIdList([...historyIdList.slice(1), id]);
    } else {
      setHistoryIdList([...historyIdList, id]);
    }
    fetchData(id);
  };

  // 返回上一步，从历史栈中弹出最后一个状态
  const goBack = () => {
    if (historyIdList.length > 1) {
      setHistoryIdList(historyIdList.slice(0, -1));
    }
    fetchData(historyIdList[historyIdList.length - 2]);
  };

  useEffect(() => {
    fetchData(uvId);
  }, []);

  const fetchData = async (id) => {
    const data = await schemaService.loadSchema(undefined, id);
    setSchemaData(data || {});
  };

  const handleCtrlClick = async (obj) => {
    const { type, id } = obj;
    if (type == "UE") {
      
      const res = await api.fetchUeMapJson({ pkid: id })
      const { ueMap, ...data } = res;
      const project = await api.fetchProject({ prjId: data.prjId })
      const newData = {
        ...data,
        bizNodeType: "UE",
        children: JSON.parse(ueMap),
      };
      setMapData({
        root: newData,
        project: project
      });
      showModal();
    } else if (type == "ALG") {
      api
        .fetchAlgMapJson({
          pkid: id,
        })
        .then((res) => {
          const { algMap, ...data } = res;
          const newData = {
            ...data,
            bizNodeType: "UIALG",
            children: JSON.parse(algMap),
          };
          setMapData({
            root: newData,
          });
          showModal();
        })
        .catch((err) => {
          message.error(err.message);
        });
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const tree = useMemo(() => {
    return shcemaData && shcemaData.uvMap
      ? transformToTreeNode(JSON.parse(shcemaData.uvMap))
      : transformToTreeNode("");
  }, [shcemaData]);
  return (
    <div style={{ width: "100vw", height: "100vh", background: "#f0f2f5" }}>
      <Header
        setScaleNum={setScaleNum}
        isTemplate={isTemplate}
        shcemaData={shcemaData}
      />
      {/* 测试用 */}
      {/* <ShcemaDataPreview shcemaData={shcemaData} uvMode={uvMode} /> */}
      <section className="formily-preview-content">
        {shcemaData.uvType == "MOBILE" ? (
          <div className="formily-preview-content-wrapper">
            <div
              style={{
                transform: `scale(${scaleNum})`,
                transformOrigin: scaleNum <= 1 ? "center" : "top center",
              }}
              className="formily-preview-content-mobile"
            >
              <img
                className="mobile-img"
                src="//img.alicdn.com/imgextra/i4/O1CN01ehfzMc1QPqY6HONTJ_!!6000000001969-55-tps-459-945.svg"
                alt=""
              />
              <div className="mobile-content">
                <div
                  style={{
                    overflow: "overlay",
                    height: "100%",
                    cursor: "auto",
                    userSelect: "text",
                  }}
                >
                  <Iframe
                    ref={iframeRef}
                    tree={tree}
                    changeData={pushToHistory}
                    handleCtrlClick={handleCtrlClick}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <PreviewWidget
            {...props}
            uvMode={uvMode}
            handleCtrlClick={handleCtrlClick}
            tree={tree}
            initParms={jsonObject}
          />
        )}
      </section>
      <Modal
        title="界面能力"
        open={isModalOpen}
        width={"70%"}
        bodyStyle={{ height: "74vh" }}
        centered
        destroyOnClose
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            关闭
          </Button>,
        ]}
      >
        <SoftwareMap mapData={mapData} />
      </Modal>
    </div>
  );
};

export default FormilyPreview;
