import {lazy, Suspense} from 'react'
import {redirect, useParams, useSearchParams} from 'react-router-dom'
import {Spin} from 'antd'
import {useUser} from '@/biz/user.mjs'
import useHTTP from '@/hooks/useHTTP.mjs'
import runAsync from '@/script/runAsync.mjs'
import MainPage from './MainPage.jsx'
import {getSearchParamsAsObject} from '@/script/CommonUtils.mjs'


const ModuleFallback = () => {
    const style = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
    }

    return (
        <Spin
            style={style}
            spinning
            tip="加载组件..."
        />
    )
}

const ModuleWrapper = () => {
    const {'*': path} = useParams()
    const [searchParams] = useSearchParams()
    const Module = lazy(() => import(`./panes/${path}/${path}.jsx`))
    // Object.fromEntries会丢失数组传参，自定义方法封装参数，兼容数组传参
    const props = getSearchParamsAsObject(searchParams); //Object.fromEntries(searchParams.entries())

    return (
        <Suspense fallback={<ModuleFallback />}>
            <Module {...props} />
        </Suspense>
    )
}

export default () => {
    const http = useHTTP()
    const user = useUser()

    const loader = async ({request: {url}}) => {
        const {pathname, search} = new URL(url)

        if (! user) {
            if ('/' !== pathname) {
                const r = encodeURIComponent(pathname + search)
                return redirect(`/login?r=${r}`)
            }
            else {
                return redirect('/login')
            }
        }

        if ('/' !== pathname) {
            return null
        }

        return redirect("/Workbench")

        // const {currPrjId, currPrjSver} = user
        //
        // if (currPrjId && currPrjSver) {
        //     const readDefaultMapId = (prjId, sVer) => runAsync(
        //         async () => {
        //             const map = await http.get(
        //                 '/basmaps/samap', {prjId, sVer}
        //             )
        //
        //             if (map) {
        //                 return map.pkid
        //             }
        //             else {
        //                 return ''
        //             }
        //         },
        //
        //         {
        //             error: {content: (err) => `查询失败: ${err.message}`},
        //             loading: {show: true},
        //             success: {show: false},
        //         }
        //     )
        //
        //     const id = await readDefaultMapId(currPrjId, currPrjSver)
        //
        //     if (id) {
        //         return redirect('/SaList')
        //     }
        // }
        //
        // return redirect('/ProjectList')
    }

    return {
        children: [
            {
                element: <ModuleWrapper />,
                path: '*',
            }
        ],

        element: <MainPage />,
        loader,
        path: '/',
    }
}
