import 'antd/dist/antd.less'
import {ConfigProvider} from 'antd'
import zhCN from 'antd/lib/locale/zh_CN'

import moment from 'moment'
import 'moment/locale/zh-cn'
moment.locale('zh-cn')

const AntDesign = (props) => {
    const config = {
    }
    return (
        <ConfigProvider
            {...config}
            {...props}
            locale={zhCN}
        />
    )
}

export default AntDesign
