import {css} from "@emotion/react";
import {useEffect, useState} from 'react';
import {Button, Form, Input, Space, Typography, Tabs} from 'antd';
import {LockOutlined, UserOutlined} from '@ant-design/icons';
import PrismHighlighter from "@/components/PrismHighlighter/PrismHighlighter";

const cssViewer = css({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    // padding: 4,
})

const cssTabs = css({
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    flexGrow: 1,
    '& .ant-tabs-nav::before': {
        border: 'none!important'
    },
    '& .ant-tabs-nav': {
        marginBottom: 0,
        '& .ant-tabs-nav-list': {
            '& .ant-tabs-tab': {
                backgroundColor: 'rgb(34, 32, 36)',
                // borderColor: 'rgb(255,255,255,0.65)',
                color: 'rgb(255,255,255,0.65)',
                // borderTop: '1px solid #f0f0f0',
                borderImage: 'linear-gradient(to right, #8f41e9, #0099CC, #8f41e9) 1',
                borderBottom: 'none',

            },
            '& .ant-tabs-tab.ant-tabs-tab-active': {
                backgroundColor: 'rgb(40, 38, 43)',
                // color: 'rgb(255, 255, 255)!important',
            },
            '& .ant-tabs-tab-remove':{
                color: 'rgb(255, 255, 255)',
            },
            '& .ant-tabs-tab-btn':{
                // color: 'rgb(255, 255, 255)!important',
            }
        }
    },
    '& .ant-tabs-content-holder': {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        flexGrow: 1,
        '& .ant-tabs-content': {
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            flexGrow: 1,
            borderTop: '1px solid #f0f0f0',
            borderImage: 'linear-gradient(to right, #8f41e9, #0099CC, #8f41e9) 1',
            '& .ant-tabs-tabpane': {
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
                flexGrow: 1,
                paddingLeft: 4,
                paddingRight: 4,

                '&>.content': {
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'auto',
                    flexGrow: 1,
                    color: 'white'
                }
            },
            '& .ant-tabs-tabpane-hidden': {
                display: 'none',
            },
        }
    }
})

const Viewer = ({newOpenItem, ...props}) => {
    const [activeKey, setActiveKey] = useState();
    const [openedItems, setOpenedItems] = useState([])

    const onChange = (newActiveKey) => {
        setActiveKey(newActiveKey);
    };

    const onEdit = (targetKey, action) => {
        if (action !== 'add') {
            for (let i = 0; i < openedItems.length; i++) {
                if (openedItems[i].pkid == targetKey) {
                    if (openedItems[i - 1]) {
                        setActiveKey(openedItems[i - 1].pkid)
                    } else if (openedItems[i + 1]) {
                        setActiveKey(openedItems[i + 1].pkid)
                    } else {
                        setActiveKey(null)
                    }
                }
            }
            setOpenedItems(openedItems.filter(({pkid}) => pkid != targetKey))
        }
    }

    useEffect(() => {
        if (newOpenItem) {
            console.log('newOpenItem', newOpenItem)
            if (newOpenItem.type === 'file') {
                if (openedItems.find(openedItem => openedItem.pkid === newOpenItem.pkid)) {
                    //切换激活面板
                    console.log('切换激活面板', newOpenItem)
                    setActiveKey(newOpenItem.pkid);
                } else {
                    console.log('打开新面板', newOpenItem)
                    //打开新面板
                    setOpenedItems([...openedItems, newOpenItem]);
                    setActiveKey(newOpenItem.pkid);
                }
            }
        }
    }, [newOpenItem])

    return (
        <div css={cssViewer}>
            <Tabs
                size='small'
                hideAdd
                css={cssTabs}
                type="editable-card"
                onChange={onChange}
                activeKey={activeKey}
                onEdit={onEdit}
                items={openedItems.map(({pkid, name, fileContent}) => {
                    return {
                        label: name,
                        children: (
                            <div className='content'>
                                <PrismHighlighter name={name} content={fileContent}/>
                                {/*<pre>*/}
                                {/*    {fileContent}*/}
                                {/*</pre>*/}
                            </div>
                        ),
                        key: pkid,
                    }
                })}
            />
        </div>
    )
}

export default Viewer;
