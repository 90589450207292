import { Table as AntdTable } from 'antd';

const Table = (props) => {
    const cssTable = {
        '.ant-table-cell': {
            whiteSpace: 'nowrap',
            textAlign: 'left',
        },

        // 增加表格头样式
        '.ant-table-thead > tr >th': {
            fontWeight: 'bold',
        },
    }

    return <AntdTable  {...props} css={cssTable} scroll={{ x: true, y: '53vh' }} style={{ width: '100vw' }} />;
}

export default Table;