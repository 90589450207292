import FindPwd from './FindPwd.jsx';
import { redirect } from 'react-router-dom';
import {useUser} from '@/biz/user.mjs'

export default () => {
    const user = useUser()

    const loader = ({ request: { url } }) => {
        if (user) {
            return redirect('/PersonalCenter?key=Security');
        }
        return null;
    }

    return {
        path: '/findPwd',
        element: <FindPwd />,
        loader,
    }
}
