import {useEffect, useState} from 'react'
import {css, Global} from '@emotion/react'
import SoftwareMap from '@/components/SoftwareMap/SoftwareMap.jsx'

const globalStyles = {
    ':root': {
        width: 'min-content',
        height: 'min-content',
    },

    '#root': {
        display: 'contents',
    },
}

const cssMap = css({
    display: 'contents !important',

    '&>:first-child': {
        display: 'contents',

        '&>:first-child': {
            margin: 0,
        },
    },
})

export default function MapViewer(props) {
    const [mapData, setMapData] = useState()

    useEffect(
        () => {
            const handleMessage = ({data}) => setMapData(data)
            window.addEventListener('message', handleMessage)

            return () => {
                window.removeEventListener('message', handleMessage)
            }
        },

        []
    )

    const components = {
        NavPanel: () => null,
        NodePanel: () => null,
        StatusBar: () => null,
        ToolBar: () => null,
    }

    const handleLoad = map => {
        map.execute(() => map.selectNodes([]))
    }

    return (
        <div id="map-viewer">
            <Global styles={globalStyles} />

            {
                mapData &&

                <SoftwareMap
                    css={cssMap}
                    components={components}
                    mapData={mapData}
                    onLoad={handleLoad}
                    {...props}
                />
            }
        </div>
    )
}
