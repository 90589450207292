import {css} from "@emotion/react";
import {shortStyles, borderStyles, fontFamilies} from "@/components/DiagramEditor/style/style";
import ColorPicker from "@/components/ColorPicker/ColorPicker";
import {Select, Input, InputNumber, Button} from "antd";

const cssDiagramProperty = css({
    display: 'flex',
    flexDirection: 'column',
    userSelect: 'none',
    width: 250,
    flexShrink: 0,
    height: 'calc(100% - 42px)',
    borderLeft: '1px solid #dadce0',
    // padding: 10,
    background: '#f0f0f0',
    overflow: 'auto',
    position: 'absolute',
    right: 0,
    top: 42,
    '& .setting-block': {
        display: 'flex',
        flexDirection: 'column',
    }
})

const cssBlockTitle = css({
    fontSize: 12,
    fontWeight: 'bold',
    padding: 8,
    borderBottom: '1px solid #dadce0',
})

const cssBlock = css({
    padding: 8,
    // borderBottom: '1px solid #dadce0',
    display: 'flex',
    gap: 8,
    // flexWrap: 'wrap',
    alignItems: 'center',
    '& > span': {
        width: 59,
        fontSize: 12,
        flexShrink: 0,
        flexGrow: 0,
        marginRight: 10,
        textAlign: 'right',
    },
    "& > div": {
        flexGrow: 1,
    }
})

const cssShortStyle = css({
    width: 20,
    height: 20,
    // margin: '0 10px 5px 0',
    boxSizing: 'border-box',
    border: '1px solid #fff',
    cursor: 'pointer',
})

const cssSelectBorderBottomStyle = css({
    height: 4,
    borderBottomWidth: 1,
    borderBottomColor: 'black',
    // marginTop: 4
})

const borderWidthOptions = Array(11).fill().map((_, i) => i)
export default ({
                    lf,
                    setLf,
                    activeEdges,
                    setActiveEdges,
                    activeNodes,
                    setActiveNodes,
                    elementProperty,
                    setElementProperty,
                    onlyEdge,
                    refContainer,
                }) => {

    const setStyle = (item) => {
        // 处理属性面板回调
        activeNodes.forEach(({id}) => {
            lf.setProperties(id, item)
        })
        activeEdges.forEach(({id}) => {
            lf.setProperties(id, item)
        })

        let properties = {}
        const {nodes, edges} = lf.getSelectElements()
        nodes.forEach(node => {
            properties = {...properties, ...node.properties}
        })
        edges.forEach(edge => {
            properties = {...properties, ...edge.properties}
        })
        setElementProperty(properties)
    }

    const setLayer = (type, value) => {
        if (type === 'string') {
            activeNodes.forEach(({id}) => {
                lf.setElementZIndex(id, value)
            })
            activeEdges.forEach(({id}) => {
                lf.setElementZIndex(id, value)
            })
        }
    }

    const handleShortStyle = (item) => {
        setStyle(item)
    }

    const handleColorProperty = (color, type) => {
        setStyle({
            [type]: color,
        })
    }

    const handleBorderStyle = (value) => {
        setStyle({
            borderStyle: value,
        })
    }

    const handleBorderWidth = (value) => {
        setStyle({
            borderWidth: value,
        })
    }

    const handleFontSize = (value) => {
        setStyle({
            fontSize: value,
        })
    }

    const handleFontFamily = (value) => {
        setStyle({
            fontFamily: value,
        })
    }

    const handleLayer = (type, value) => {
        setLayer(type, value)
    }

    return (
        <div css={[cssDiagramProperty]}>
            <div css={[cssBlockTitle]}>快捷样式</div>
            <div css={[cssBlock, {flexWrap: 'wrap'}]}>
                {
                    shortStyles.map(({backgroundColor, borderWidth, borderColor}, index) => {
                        return (
                            <div
                                css={[cssShortStyle, {backgroundColor, borderColor, borderWidth}]}
                                onClick={() => handleShortStyle({backgroundColor, borderWidth, borderColor})}
                            />
                        )
                    })
                }
            </div>
            <div css={[cssBlockTitle]}>样式属性</div>
            <div css={[cssBlock]}>
                <span>背景色</span>
                <ColorPicker width={40} value={elementProperty.backgroundColor} onChange={(hex) => handleColorProperty(hex, "backgroundColor")}/>
            </div>
            <div css={[cssBlock]}>
                <span>线条样式</span>
                <div>
                    <Select
                        style={{width: '100%'}}
                        size='small'
                        optionLabelProp="label"
                        onChange={value => handleBorderStyle(value)}
                        value={elementProperty.borderStyle}
                    >
                        {
                            borderStyles.map(({label, value}) => {
                                return (
                                    <Select.Option value={value} label={label}>
                                        {/*<div css={cssSelectBorderBottomStyle}>1</div>*/}
                                        {label}
                                    </Select.Option>
                                )
                            })
                        }
                    </Select>
                </div>
            </div>
            <div css={[cssBlock]}>
                <span>线条颜色</span>
                <ColorPicker width={40} value={elementProperty.borderColor} onChange={(hex) => handleColorProperty(hex, "borderColor")}/>
            </div>
            <div css={[cssBlock]}>
                <span>线条宽度</span>
                <div>
                    <Select
                        style={{width: '100%'}}
                        size='small'
                        optionLabelProp="label"
                        onChange={value => handleBorderWidth(value)}
                        value={elementProperty.borderWidth}
                        options={borderWidthOptions.map(bw => ({label: bw + 'px', value: bw}))}
                    >
                    </Select>
                </div>
            </div>
            <div css={[cssBlock]}>
                <span>文本颜色</span>
                <ColorPicker width={40} value={elementProperty.fontColor} onChange={(hex) => handleColorProperty(hex, "fontColor")}/>
            </div>
            <div css={[cssBlock]}>
                <span>文本大小</span>
                <InputNumber size='small' value={elementProperty.fontSize} min={12} max={30} onChange={value => handleFontSize(value)} addonAfter="px"/>
            </div>
            <div css={[cssBlock]}>
                <span>文本字体</span>
                <div>
                    <Select
                        style={{width: '100%'}}
                        size='small'
                        optionLabelProp="label"
                        onChange={value => handleFontFamily(value)}
                        value={elementProperty.fontFamily}
                        options={fontFamilies.map(({value}) => ({label: value, value}))}
                    >
                    </Select>
                </div>
            </div>
            <div css={[cssBlock]}>
                <span>图层顺序</span>
                <div css={{display: 'flex', gap: 8}}>
                    <Button size='small' onClick={() => handleLayer('string', 'top')}>置为顶层</Button>
                    <Button size='small' onClick={() => handleLayer('string', 'bottom')}>置为底层</Button>
                </div>
            </div>
        </div>
    )
}
