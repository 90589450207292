import {h} from '@logicflow/core'
import RectNode from '../basic/RectNode'
import {getShapeStyleFuction, getTextStyleFunction} from '../getShapeStyleUtil'

// 除号
class EndFlowNodeModel extends RectNode.model {
    initNodeData(data) {
        super.initNodeData(data)
        this.width = 100
        this.height = 40
    }

    getNodeStyle() {
        const style = super.getNodeStyle()
        const properties = this.getProperties()
        return getShapeStyleFuction(style, {backgroundColor: 'rgb(248, 206, 204)', borderWidth: '1px', borderColor: 'rgb(184, 84, 80)',...properties})
    }

    getTextStyle() {
        const style = super.getTextStyle()
        const properties = this.getProperties()
        if (properties.backgroundColor) {
            style.backgroundStyle = {
                fill: properties.backgroundColor
            }
        }
        return getTextStyleFunction(style, properties)
    }

    setAttributes () {
        super.setAttributes()
        if (!this.text.value) {
            this.text.value = '结束'
        }
    }
}

class EndFlowNodeView extends RectNode.view {
    getResizeShape() {
        const {x, y, width, height} = this.props.model //x，y 代表中心点
        const style = this.props.model.getNodeStyle()

        const startX = x - width / 2
        const startY = y - height / 2


        const attrs = {
            ...style,
            x,
            y,
            width,
            height,
        }

        // 最底层矩形
        const rectAttrs = {
            ...attrs,
            x: startX,
            y: startY,
            rx: 20,
        }

        return h('g', {}, [
            h('rect', {...rectAttrs}),
        ])
    }

}

export default {
    type: 'busin-endflow',
    view: EndFlowNodeView,
    model: EndFlowNodeModel
}
