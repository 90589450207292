const oprType = (key) => {
    switch (key) {
        case 'GENERAL': return null;
        case 'MENU': return '点击菜单';
        case 'BUTTON': return '点击按钮';
        case 'CLICK_URL': return '点击链接';
        case 'CLICK_TAB': return '点击页签';
        case 'SHORTCUT': return '快捷键';
        case 'CHECK_ROW': return '勾选行记录';
        case 'INPUT': return '录入数据';
    }
    return null;
}

export default oprType;