import {css} from '@emotion/react'
import {Outlet} from 'react-router-dom'
import MainHeader from './MainHeader'

const cssPage = css({
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    width: '100vw',
    height: '100vh',
    overflow: 'hidden',
})

const MainPage = () => {
    return (
        <div css={cssPage}>
            <MainHeader />
            <Outlet />
        </div>
    )
}

export default MainPage
