import {SWRConfig} from 'swr'
import useSse from '@/biz/useSse.jsx'
import {LauncherProvider} from '@/components/Launcher/Launcher.jsx'
import ModalManager from '@/components/Modal/ModalManager.jsx'
import {PopoverManager} from '@/components/Popover/index.mjs'
import PreviewImages from '@/components/PreviewImages/PreviewImages.jsx'
import useDocTitle from '@/hooks/useDocTitle.mjs'
import AntDesign from './AntDesign.jsx'
import License from './License.jsx'
import Router from './Router.jsx'
import useBaiduAnalytics from './useBaiduAnalytics.mjs'

const swrOptions = {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
}

const App = () => {
    useBaiduAnalytics()
    useSse()
    // useDocTitle(import.meta.env.VITE_APP_NAME)

    return (
        <AntDesign>
            <SWRConfig value={swrOptions}>
                <PopoverManager>
                    <ModalManager>
                        <PreviewImages>
                            <LauncherProvider>
                                <License>
                                    <Router />
                                </License>
                            </LauncherProvider>
                        </PreviewImages>
                    </ModalManager>
                </PopoverManager>
            </SWRConfig>
        </AntDesign>
    )
}

export default App
