import {Button} from "antd";

export default () => {
    return (
        <div>
            <Button onClick={() => {
                window.open(`/flowEditor?flowId=02f1d415f83e4a3da995a0a4fedb86ae&flowName=xxx图`)
            }}>打开编辑器</Button>
        </div>
    )
}
