// 基础图形
import CircleNode from './basic/CircleNode.js'
import RectNode from './basic/RectNode.js'
import RectRadiusNode from './basic/RectRadiusNode.js'
import EllipseNode from './basic/EllipseNode.js'
import TextNode from './basic/TextNode.js'
import DiamondNode from './basic/DiamondNode.js'
// path绘制的个性化图形
import CylindeNode from './path/CylindeNode.js'
import TriangleNode from './path/TriangleNode.js'
import ParallelogramNode from './path/ParallelogramNode.js'
import ActorNode from './path/ActorNode.js'
import StarNode from './path/Star.js'
import PentagonNode from './path/PentagonNode.js'
import HexagonNode from './path/HexagonNode.js'
import SeptagonNode from './path/SeptagonNode.js'
import HeptagonNode from './path/HeptagonNode.js'
import TrapezoidNode from './path/TrapezoidNode.js'
import CrossNode from './path/CrossNode.js'
import MinusNode from './path/MinusNode.js'
import TimesNode from './path/TimesNode.js'
import DivideNode from './path/DivideNode.js'
// 多边形绘制的箭头
import LeftArrow from './arrow/LeftArrow.js'
import RightArrow from './arrow/RightArrow.js'
import HorizontalArrow from './arrow/HorizontalArrowNode.js'
import UpArrow from './arrow/UpArrowNode.js'
import DownArrow from './arrow/DownArrowNode.js'
import VerticalArrow from './arrow/VerticalArrowNode.js'
// image绘制图片节点
import ImageSetting from './image/Setting.js'
import ImageUser from './image/User.js'
import ImageCloud from './image/Cloud.js'
// image绘制左上角icon节点
import IconMessage from './icon/Message.js'
// 注册边
import Ployline from './edge/Polyline.js'
import Line from './edge/Line.js'
import Bezier from './edge/Bezier.js'

//注册业务流程
import SubFlowNode from "./busin/SubFlowNode.js";
import StartFlowNode from "./busin/StartFlowNode.js";
import EndFlowNode from "./busin/EndFlowNode.js";
import OnlineDataNode from "./busin/OnlineDataNode.js";
import OfflineDataNode from "./busin/OfflineDataNode.js";
import OnlineJobNode from "./busin/OnlineJobNode.js";
import OfflineJobNode from "./busin/OfflineJobNode.js";
export const registerCustomNode = (lf) => {
    // 注册基础图形
    lf.register(CircleNode)
    lf.register(RectNode)
    lf.register(RectRadiusNode)
    lf.register(EllipseNode)
    lf.register(DiamondNode)
    lf.register(TextNode)
    // 注册path绘制的个性化图形
    lf.register(CylindeNode)
    lf.register(TriangleNode)
    lf.register(ParallelogramNode)
    lf.register(ActorNode)
    lf.register(StarNode)
    lf.register(PentagonNode)
    lf.register(HexagonNode)
    lf.register(SeptagonNode)
    lf.register(HeptagonNode)
    lf.register(TrapezoidNode)
    lf.register(CrossNode)
    lf.register(MinusNode)
    lf.register(TimesNode)
    lf.register(DivideNode)
    // 注册多边形绘制的箭头
    lf.register(LeftArrow)
    lf.register(RightArrow)
    lf.register(HorizontalArrow)
    lf.register(UpArrow)
    lf.register(DownArrow)
    lf.register(VerticalArrow)
    // 注册image绘制图片节点
    lf.register(ImageSetting)
    lf.register(ImageUser)
    lf.register(ImageCloud)
    // 注册image绘制左上角icon节点
    lf.register(IconMessage)
    // 注册边
    lf.register(Ployline)
    lf.register(Line)
    lf.register(Bezier)

    //注册业务流程
    lf.register(SubFlowNode)
    lf.register(StartFlowNode)
    lf.register(EndFlowNode)
    lf.register(OnlineDataNode)
    lf.register(OfflineDataNode)
    lf.register(OnlineJobNode)
    lf.register(OfflineJobNode)
}

