import {useEffect, useRef, useState} from 'react'
import {css} from '@emotion/react'

const cssNode = css({
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    cursor: 'pointer',
})

const cssShape = css({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
})

const cssText = css({
    boxSizing: 'content-box',
    zIndex: 1,
    width: 80,
    minHeight: '1em',
    color: '#000',
    fontSize: 13,
    fontWeight: 'lighter',
    lineHeight: '1.2',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
})

const LEG = 12
const BW = 2

const getContentPaddings = () => [BW, BW + LEG, BW, BW + LEG]

const Shape = ({textWidth, textHeight, ...props}) => {
    const [pt, pr, pb, pl] = getContentPaddings()
    const w = pl + textWidth + pr
    const h = pt + textHeight + pb

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox={`0 0 ${w} ${h}`}
            {...props}
        >
            <path
                d={`
                    M ${BW / 2} ${h / 2}
                    L ${BW / 2 + LEG} ${BW / 2}
                    H ${w - BW / 2 - LEG}
                    L ${w - BW / 2} ${h / 2}
                    L ${w - BW / 2 - LEG} ${h - BW / 2}
                    H ${BW / 2 + LEG}
                    Z
                `}

                fill="#50c3f7"
            />
        </svg>
    )
}

export default function Ue ({children, ...props}) {
    const refText = useRef()
    const [rectText, setRectText] = useState({textWidth: 0, textHeight: 0})

    useEffect(
        () => {
            const {clientHeight, clientWidth} = refText.current

            setRectText({
                textWidth: clientWidth,
                textHeight: clientHeight,
            })
        },

        []
    )

    const paddings = getContentPaddings(rectText)

    const style = {
        ...props.style,
        padding: paddings.map((n) => `${n}px`).join(' '),
    }

    return (
        <div
            css={cssNode}
            {...props}
            style={style}
        >
            <Shape
                css={cssShape}
                {...rectText}
            />

            <div
                ref={refText}
                css={cssText}
            >
                {children}
            </div>
        </div>
    )
}
