import {useState} from 'react'
import {useParams} from 'react-router-dom'
import {css} from '@emotion/react'
import useDocTitle from '@/hooks/useDocTitle.mjs'
import SoftwareMap from '@/components/SoftwareMap/SoftwareMap.jsx'
import Footer from './Footer.jsx'

const cssMap = css({
    width: '100%',
    height: '100%',
})

const cssFooter = css({
    position: 'absolute',
    bottom: 20,
})

export default function SharePage(props) {
    const {id} = useParams()
    const [docTitle, setDocTitle] = useState('')
    useDocTitle(docTitle)

    const handleLoad = ({mapName}) => {
        setDocTitle(mapName)
    }

    return (
        <>
            <SoftwareMap
                css={cssMap}
                id={id}
                mode="share"
                toolbar={null}
                onLoad={handleLoad}
                {...props}
            />

            <Footer css={cssFooter} />
        </>
    )
}
